import React, { useMemo } from 'react';
import { format } from 'date-fns';
import {
  AdminVettingProcess,
  BasicVettingProcess,
} from '@a_team/models/dist/vetting-processes/vetting-processes';
import { FetchVettingProcessesSortingKeys } from '@ateams/api/dist/endpoints/vetting-process';
import { VettingAdminColumns, VettingBasicColumns } from '.';

import { NameField, NameWithOptOutIndication } from './columns/name';
import { InterviewTypeField } from './columns/interview-type';
import {
  InterviewerField,
  InterviewerReadOnlyField,
} from './columns/interviewer';
import { MainRoleField } from './columns/main-role';
import { BuilderInfoField } from './columns/builder-info';
import { CodeSampleField } from './columns/code-sample';
import { ExpertiseField } from './columns/expertise';
import { InteractionExperienceField } from './columns/interaction-experience';
import { EnglishField } from './columns/english';
import { AccentField } from './columns/accent';
import { StatusField } from './columns/status';
import { InterviewerSuggestionsField } from './columns/interviewer-suggestions';
import { InterviewDateField } from './columns/interview-date';
import { RemoveApplicationField } from './columns/remove-application';
import { ContactOwnerField } from './columns/contact-owner';
import { SubmitFeedbackField } from './columns/submit-feedback';
import { FeedbackScoresActionsField } from './columns/feedback-scores-actions';
import { ReachoutEmailDatesField } from './columns/email-dates';
import { EvaluationInviteField } from './columns/evaluation-invite';
import { CallRecordingUrls } from './columns/call-recording-urls';
import { CancelInterviewField } from './columns/cancel-interview';
import { TranscriptUrls } from './columns/transcript-urls';
import { InterviewerNoShowField } from './columns/interviewer-no-show';
import { BookingLinkField } from './columns/booking-link';
import { useTalentSkills } from '@src/rq/useTalentSkills';
import { BuilderNoShowField } from './columns/builder-no-show';

export interface BasicVettingProcessColumnProps {
  vettingProcess: BasicVettingProcess;
  isAdminView: boolean;
}

export interface AdminVettingProcessColumnProps {
  vettingProcess: AdminVettingProcess;
  isAdminView: boolean;
}

export type VettingColumnConfig<
  T extends BasicVettingProcess = BasicVettingProcess,
  P = unknown,
> = {
  header: string;
  width: number;
  Component: React.FC<
    {
      vettingProcess: T;
      isAdminView: boolean;
      isCurrentUserTheInterviewer: boolean;
    } & P
  >;
  sortingKey?: FetchVettingProcessesSortingKeys;
};

export const basicVettingProcessColumns: Record<
  VettingBasicColumns,
  VettingColumnConfig
> = {
  name: {
    header: 'Name',
    Component: NameField,
    width: 220,
  },
  mainRole: {
    header: 'Main Role',
    Component: MainRoleField,
    width: 140,
  },
  skillForVetting: {
    header: 'Skills for Evaluation',
    Component: ({ vettingProcess }) => {
      const skillsQueryResult = useTalentSkills({});
      const allSkills = useMemo(() => {
        return skillsQueryResult?.data || [];
      }, [skillsQueryResult]);

      if (
        vettingProcess.preVetting &&
        'codeSampleProgrammingLanguages' in vettingProcess.preVetting
      ) {
        return (
          <>
            {vettingProcess.preVetting.codeSampleProgrammingLanguages
              ?.map((talentSkill) => (talentSkill ? talentSkill.name : ''))
              .concat(
                vettingProcess.preVetting
                  ?.otherCodeSampleProgrammingLanguage as string,
              )
              .filter((el) => el)
              .join(', ')}
          </>
        );
      }

      if (vettingProcess.preVettingForm) {
        return (
          <>
            {vettingProcess.preVettingForm.selectedSkills
              .map((skillId) => {
                const skill = allSkills.find((s) => s.id === skillId);
                return skill ? skill.name : '';
              })
              .filter((name) => name)
              .join(', ')}
          </>
        );
      }

      return null;
    },
    width: 140,
  },
  interviewerReadOnly: {
    header: 'Interviewer',
    Component: InterviewerReadOnlyField,
    width: 305,
  },
  codeSample: {
    header: 'Additional Materials',
    Component: CodeSampleField,
    width: 120,
  },
  builderInfo: {
    header: 'Builder Info',
    Component: BuilderInfoField,
    width: 120,
  },
  builderNoShow: {
    header: 'No Show',
    Component: BuilderNoShowField,
    width: 205,
  },
  interviewDate: {
    header: 'Interview Date',
    Component: InterviewDateField,
    sortingKey: FetchVettingProcessesSortingKeys.InterviewDate,
    width: 225,
  },
  cancelInterview: {
    header: 'Cancel Interview',
    Component: CancelInterviewField,
    width: 205,
  },
  submitFeedback: {
    header: 'Submit Feedback',
    Component: SubmitFeedbackField,
    width: 205,
  },
  feedbackSubmitDate: {
    header: 'Feedback Submit Date',
    Component: ({ vettingProcess }) => {
      const submitDate =
        vettingProcess.feedbackV2?.submitDate ??
        vettingProcess.feedback?.submitDate;

      return submitDate ? (
        <div>{format(new Date(submitDate), 'MMM dd, yyyy')}</div>
      ) : null;
    },
    width: 180,
  },
  phoneNumber: {
    header: 'Phone Number',
    Component: ({ vettingProcess }) => (
      <div>{vettingProcess.user.phoneNumber}</div>
    ),
    width: 150,
  },
  expertise: {
    header: 'Expertise Level',
    Component: ExpertiseField,
    sortingKey: FetchVettingProcessesSortingKeys.FeedbackExpertise,
    width: 180,
  },
  interactionExperience: {
    header: 'Interpersonal Skills',
    Component: InteractionExperienceField,
    sortingKey: FetchVettingProcessesSortingKeys.FeedbackInteractionExperience,
    width: 180,
  },
  englishLevel: {
    header: 'English Proficiency',
    Component: EnglishField,
    sortingKey: FetchVettingProcessesSortingKeys.FeedbackEnglishLevel,
    width: 180,
  },
  accent: {
    header: 'English Pronunciation',
    Component: AccentField,
    sortingKey: FetchVettingProcessesSortingKeys.FeedbackAccent,
    width: 180,
  },
};

export const adminVettingProcessColumns: Record<
  VettingAdminColumns,
  VettingColumnConfig<AdminVettingProcess>
> = {
  nameWithOptOutIndication: {
    header: 'Name',
    Component: NameWithOptOutIndication,
    width: 220,
  },
  status: {
    header: 'Status',
    Component: StatusField,
    width: 265,
  },
  interviewType: {
    header: 'Evaluation Type',
    Component: InterviewTypeField,
    width: 265,
  },
  interviewer: {
    header: 'Interviewer',
    Component: InterviewerField,
    width: 305,
  },
  interviewerSuggestions: {
    header: 'Interviewer Suggestions',
    Component: InterviewerSuggestionsField,
    width: 255,
  },
  preVettingFormSentDate: {
    header: 'Reachout Email Dates',
    Component: ReachoutEmailDatesField,
    sortingKey: FetchVettingProcessesSortingKeys.FirstReachoutEmail,
    width: 240,
  },
  evaluationInvite: {
    header: 'Evaluation Invite',
    Component: EvaluationInviteField,
    width: 205,
  },
  bookingLink: {
    header: 'Booking link',
    Component: BookingLinkField,
    width: 133,
  },
  removeApplication: {
    header: 'Remove Application',
    Component: RemoveApplicationField,
    width: 225,
  },
  contactOwner: {
    header: 'Admin',
    Component: ContactOwnerField,
    width: 305,
  },
  feedbackScoresActions: {
    header: 'Feedback Scores Actions',
    Component: FeedbackScoresActionsField,
    width: 305,
  },
  callRecording: {
    header: 'Call recordings',
    Component: CallRecordingUrls,
    width: 305,
  },
  transcript: {
    header: 'Interview Transcripts',
    Component: TranscriptUrls,
    width: 305,
  },
  interviewerNoShow: {
    header: 'Interviewer No Show',
    Component: InterviewerNoShowField,
    width: 205,
  },
  createdAt: {
    header: 'Evaluation Started Date',
    Component: ({ vettingProcess }) =>
      vettingProcess.createdAt ? (
        <div style={{ whiteSpace: 'nowrap' }}>
          {format(new Date(vettingProcess.createdAt), 'MMM dd, yyyy')}
        </div>
      ) : null,
    width: 150,
  },
};
