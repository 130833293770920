import ConfirmModal from '@src/components/Modal/ConfirmModal';
import useLoadingState from '@src/hooks/useLoadingState';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import EmailForm, { EmailFormData } from './EmailForm';
import { TargeterEmailLayout } from './Layout';
import { AudienceData } from './AudienceData';
import { TargeterEmailService } from './TargeterEmailService';
import { useFieldArray, useForm } from 'react-hook-form';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { ValidAudienceFormData } from './AudienceForm';
import {
  EmailFromTargeterSendDtoEmailCategoryEnum,
  EmailFromTargeterSendDtoTypeEnum,
  EmailTemplatesControllerGetTemplateIdEnum,
} from '@a_team/user-notification-service-js-sdk';
import MissionRole, {
  MissionRoleId,
  MissionRoleStatus,
} from '@a_team/models/dist/MissionRole';
import { useUpdateMissionPostedDate } from '@src/rq/hubspot';
import { apiMissions } from '@ateams/api';
import { Spinner } from '@ateams/components';
import {
  mapTargeterApiRequest,
  queryParametersToSearchRequestBody,
} from '../SearchView/utils';
import { truncate, uniq } from 'lodash';
import { RoleFilter } from '@src/components/TeamGraphBuilderQuery';
import {
  addSubaddressToEmail,
  formationUserObject,
  getEmailFromTargeterAudiencesFormSessionStorage,
  getEmailFromTargeterFormSessionStorage,
  persistEmailFromTargeterAudiencesFormSessionStorage,
  persistEmailFromTargeterFormSessionStorage,
  usePrevious,
  FORMATION_EMAIL,
} from './utils';
import { MissionAdminObject } from '@a_team/models/dist/MissionObject';
import AuthStore from '@src/stores/Auth';
import { useMutationCloseEmailFromTargeterUsageLog } from '@src/rq/emailFromTargeter';
import { useHistory, useLocation } from 'react-router-dom';
import { CreateCampaignModal } from './CreateCampaignModal';
import { Sidebar } from './Sidebar';

export const ROLE_HEADLINE_SELECT_MAX_LENGTH = 100;

interface Props {
  onClickOnEditAudience: () => void;
  audiences: AudienceData[];
  onClickOnSelectAllAudience?: (audience: AudienceData) => void;
}

interface AudienceTotalCount {
  label: string;
  count: number;
}

const GENERATE_BODY_ON_LOAD_PARAM = 'generate-body';

const LOADING_MSG_SENDING_EMAIL = 'Sending email...';
const LOADING_MSG_SENDING_PREVEIW_EMAIL = 'Sending preview email...';
const GENERATING_EMAIL_LOADING_MSG = 'Generating email...';
const SUCCESS_MSG_SEND_EMAIL = 'Email sent';
const SUCCESS_MSG_SEND_PREVEIW_EMAIL = 'Preview email sent';
const SUCCESS_MSG_CREATE_CAMPAIGN = 'Campaign created';
const LOADING_MSG_CREATE_CAMPAIGN = 'Creating campaign...';

export const TargeterEmailView: React.FC<Props> = observer(
  ({ audiences, onClickOnEditAudience, onClickOnSelectAllAudience }) => {
    const { auth, users } = useStores();
    const location = useLocation();
    const history = useHistory();
    const [confirmPreviewModalOpen, setConfirmPreviewModalOpen] =
      React.useState(false);
    const [confirmSendEmailModalOpen, setConfirmSendEmailModalOpen] =
      React.useState(false);
    const [confirmCreateCampaignModalOpen, setConfirmCreateCampaignModalOpen] =
      React.useState(false);
    const { mutate: updateMissionPostedDate } = useUpdateMissionPostedDate();
    const { mutate: closeEmailFromTargeterUsageLog } =
      useMutationCloseEmailFromTargeterUsageLog();
    const [sendEmailLoading, setSendEmailLoading] = useLoadingState();
    const [sendPreviewLoading, setSendPreviewLoading] = useLoadingState();
    const [generateEmailLoading, setGenerateEmailLoading] = useLoadingState();
    const [audienceFormValidationLoading, setAudienceFormValidationLoading] =
      useLoadingState();
    const [validFormData, setValidFormData] = React.useState<EmailFormData>();
    const [validAudiencesFormData, setValidAudiencesFormData] = React.useState<
      Map<string, ValidAudienceFormData>
    >(new Map());

    const [audienceTotalCounts, setAudienceTotalCounts] = React.useState<
      AudienceTotalCount[]
    >([]);
    const [createCampaignLoading, setCreateCampaignLoading] = useLoadingState();
    const generateBodyOnLoad = useMemo(() => {
      return Boolean(
        new URLSearchParams(location.search).get(GENERATE_BODY_ON_LOAD_PARAM),
      );
    }, [location.search]);
    const [missionRoleOptions, setMissionRoleOptions] = React.useState<
      {
        label: string;
        value: MissionRoleId;
        data: {
          description: string;
        };
      }[]
    >();

    const [selectedMissionRoles, setSelectedMissionRoles] =
      React.useState<MissionRole[]>();

    const [selectedMission, setSelectedMission] = React.useState<
      MissionAdminObject | undefined
    >();
    const [selectedMissionDescription, setSelectedMissionDescription] =
      React.useState<string>();
    const [selectedMissionHasNoOwner, setSelectedMissionHasNoOwner] =
      React.useState<boolean>(false);
    const [totalAudienceCount, setTotalAudienceCount] = React.useState(0);
    const [totalAudienceCountLoading, setTotalAudienceCountLoading] =
      React.useState(false);
    // This is needed to prent filling the body with the template when restoring from session storage
    const [preventFillingBodyWithTemplate, setPreventFillingBodyWithTemplate] =
      React.useState(false);
    const [
      preventFillingSubjectWithTemplate,
      setPreventFillingSubjectWithTemplate,
    ] = React.useState(false);
    const [preventFillingTeamArchitect, setPreventFillingTeamArchitect] =
      React.useState(false);
    const [
      preventFillingDefaultBccAddresses,
      setPreventFillingDefaultBccAddresses,
    ] = React.useState(false);
    const [preventFillingSignatureUser, setPreventFillingSignatureUser] =
      React.useState(false);
    const [formLoaded, setFormLoaded] = React.useState(false);

    const defaultFormValues = {
      category: EmailFromTargeterSendDtoEmailCategoryEnum.MissionNotification,
      type: EmailFromTargeterSendDtoTypeEnum.CustomTemplated,
      fromUser: undefined,
      mainReplyToUser: undefined,
      additionalReplyToUsers: [],
      subject: '',
      body: '',
      missionId: '',
      bodySingleRoleMatch: '',
      bodyMultipleRoleMatch: '',
      videoURL: '',
      teamArchitectUser: undefined,
      ccAddresses: [],
      bccAddresses: [],
      signatureUser: undefined,
      gptUsageLogId: '',
    };

    const emailForm = useForm<Partial<EmailFormData>>({
      defaultValues: defaultFormValues,
    });

    const ccAddressesFieldArray = useFieldArray({
      control: emailForm.control,
      name: 'ccAddresses',
    });
    const bccAddressesFieldArray = useFieldArray({
      control: emailForm.control,
      name: 'bccAddresses',
    });
    const additionalReplyToUsersFieldArray = useFieldArray({
      control: emailForm.control,
      name: 'additionalReplyToUsers',
    });

    useEffect(() => {
      const emailFormData = emailForm.getValues();
      emailForm.formState.isDirty &&
        persistEmailFromTargeterFormSessionStorage(emailFormData);
    }, [emailForm.watch()]);

    useEffect(() => {
      if (validAudiencesFormData.size) {
        persistEmailFromTargeterAudiencesFormSessionStorage(
          validAudiencesFormData,
        );
      }
    }, [validAudiencesFormData]);

    useEffect(() => {
      const emailAudiencesFormData =
        getEmailFromTargeterAudiencesFormSessionStorage();
      if (emailAudiencesFormData) {
        setValidAudiencesFormData(emailAudiencesFormData);
      }
      const emailFormData = getEmailFromTargeterFormSessionStorage();
      if (emailFormData) {
        if (emailFormData.missionId && emailFormData.body) {
          setPreventFillingBodyWithTemplate(true);
        }
        if (emailFormData.missionId && emailFormData.subject) {
          setPreventFillingSubjectWithTemplate(true);
        }
        if (emailFormData.missionId && emailFormData.teamArchitectUser) {
          setPreventFillingTeamArchitect(true);
        }
        if (emailFormData.signatureUser && emailFormData.signatureUser.email) {
          setPreventFillingSignatureUser(true);
        }
        setPreventFillingDefaultBccAddresses(true);
        emailForm.reset(emailFormData);
      }
      setFormLoaded(true);
    }, []);

    const emailFormEmailType = emailForm.watch('type');
    const emailFormEmailCategory = emailForm.watch('category');
    const emailFormMissionId = emailForm.watch('missionId');
    const emailFormMissionIdDirty =
      emailForm.formState.dirtyFields['missionId'] === true;
    const emailFormTeamArchitectUser = emailForm.watch('teamArchitectUser');
    const emailFormFromUser = emailForm.watch('fromUser');
    const emailFormSignatureUser = emailForm.watch('signatureUser');

    const prevEmailFormMissionId = usePrevious<string | undefined>(
      emailFormMissionId,
    );

    const enabledAudiences = audiences.filter(
      (audience) => !validAudiencesFormData.get(audience.label)?.disabled,
    );

    const showCreateCampaignButton = useMemo(() => {
      return enabledAudiences.every(
        (audience) => !!audience.automatedReachoutPreset,
      );
    }, [emailFormEmailType]);

    useEffect(() => {
      if (emailFormMissionId && generateBodyOnLoad) {
        onClickGenerateEmail();
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete(GENERATE_BODY_ON_LOAD_PARAM);
        history.push({
          pathname: location.pathname,
          search: searchParams.toString(),
        });
      }
    }, [generateBodyOnLoad, emailFormMissionId]);

    useEffect(() => {
      if (
        !emailFormEmailType ||
        (emailFormEmailType !== EmailFromTargeterSendDtoTypeEnum.RichReachout &&
          emailFormEmailType !==
            EmailFromTargeterSendDtoTypeEnum.RichReachoutV2) ||
        !emailFormFromUser ||
        !selectedMission
      ) {
        return;
      }
      if (preventFillingSignatureUser) {
        setPreventFillingSignatureUser(false);
        return;
      }
      if (emailFormFromUser.email !== FORMATION_EMAIL) {
        emailForm.setValue('signatureUser', emailFormFromUser);
      } else if (emailFormTeamArchitectUser) {
        emailForm.setValue('signatureUser', emailFormTeamArchitectUser);
      } else if (selectedMission?.owner) {
        emailForm.setValue('signatureUser', {
          id: selectedMission?.owner?.uid || '',
          email: selectedMission?.owner?.email || '',
          fullName: selectedMission?.owner?.fullName || '',
          imageUrl: selectedMission?.owner?.profilePictureURL || '',
        });
      }
    }, [emailFormFromUser, emailFormTeamArchitectUser, selectedMission]);

    useEffect(() => {
      if (
        (!confirmSendEmailModalOpen && !confirmCreateCampaignModalOpen) ||
        !validFormData
      ) {
        return;
      }
      setTotalAudienceCountLoading(true);
      Promise.all(
        enabledAudiences.map(async (audience) => ({
          filters: await getSearchServiceFiltersFromAudience(
            auth,
            audience.filters,
            validFormData.category,
          ),
          recipients: audience.selectedBuilders,
          ...validAudiencesFormData.get(audience.label),
        })),
      ).then((audienceFilters) => {
        TargeterEmailService.getTotalAudienceCount(auth, audienceFilters).then(
          (response) => {
            setTotalAudienceCount(response.count);
            setTotalAudienceCountLoading(false);
          },
        );
      });
    }, [confirmSendEmailModalOpen, confirmCreateCampaignModalOpen]);

    useEffect(() => {
      Promise.all(
        audiences.map(async (audience) =>
          TargeterEmailService.getAudienceCount(
            auth,
            await getSearchServiceFiltersFromAudience(
              auth,
              audience.filters,
              validFormData?.category ||
                emailFormEmailCategory ||
                EmailFromTargeterSendDtoEmailCategoryEnum.MissionNotification,
            ),
          ),
        ),
      ).then((countResponses) => {
        setAudienceTotalCounts(
          countResponses.map((countResponse, idx) => ({
            label: audiences[idx].label,
            count: countResponse.count,
          })),
        );
      });
    }, [audiences, emailFormEmailCategory, validFormData]);

    useEffect(() => {
      if (
        emailFormEmailCategory !==
        EmailFromTargeterSendDtoEmailCategoryEnum.MissionNotification
      ) {
        emailForm.setValue(
          'type',
          EmailFromTargeterSendDtoTypeEnum.CustomTemplated,
        );
      }
    }, [emailFormEmailCategory]);

    useEffect(() => {
      if (emailFormMissionId) {
        apiMissions
          .adminGetMissionById(auth, emailFormMissionId)
          .then((mission) => {
            setSelectedMission(mission);
            setSelectedMissionDescription(mission.description);
            const openMissionRoles = mission.roles.filter(
              (role) => role.status === MissionRoleStatus.Open && !role.user,
            );
            setSelectedMissionRoles(openMissionRoles);
            emailForm.setValue('videoURL', mission.videoURL || '');
            if (!preventFillingSubjectWithTemplate) {
              emailForm.setValue('subject', `A.Team // ${mission.title}`);
            } else {
              setPreventFillingSubjectWithTemplate(false);
            }
            setMissionRoleOptions(
              openMissionRoles.map((role, idx) => ({
                label: `${idx + 1} - ${role.category.title}`,
                value: role.rid,
                data: {
                  description: truncate(role.headline, {
                    length: ROLE_HEADLINE_SELECT_MAX_LENGTH,
                  }),
                },
              })),
            );
            if (!mission.owner) {
              setSelectedMissionHasNoOwner(true);
            } else {
              setSelectedMissionHasNoOwner(false);
            }
            if (
              !mission.owner &&
              auth.currentUser &&
              !preventFillingTeamArchitect
            ) {
              emailForm.setValue('teamArchitectUser', {
                id: auth.currentUser.uid,
                email: auth.currentUser.email,
                fullName: auth.currentUser.fullName,
                imageUrl: auth.currentUser.profilePictureURL,
              });
            } else if (!preventFillingTeamArchitect) {
              emailForm.setValue('teamArchitectUser', undefined);
            } else {
              setPreventFillingTeamArchitect(false);
            }
            if (
              prevEmailFormMissionId &&
              prevEmailFormMissionId !== emailFormMissionId
            ) {
              resetAudiencesFormData();
            }
            if (
              (emailFormEmailType ===
                EmailFromTargeterSendDtoTypeEnum.RichReachout ||
                emailFormEmailType ===
                  EmailFromTargeterSendDtoTypeEnum.RichReachoutV2) &&
              mission.description &&
              !preventFillingBodyWithTemplate
            ) {
              emailForm.setValue(
                'body',
                `${mission.companyStory}
                ${mission.description}`,
              );
            } else if (preventFillingBodyWithTemplate) {
              setPreventFillingBodyWithTemplate(false);
            }
          });
      } else if (emailFormMissionIdDirty) {
        // We clear the audience form data and some fields if the mission is cleared by the user
        setSelectedMission(undefined);
        setSelectedMissionDescription('');
        setSelectedMissionRoles([]);
        emailForm.setValue('videoURL', '');
        setMissionRoleOptions([]);
        resetAudiencesFormData();
        emailForm.setValue('teamArchitectUser', undefined);
        setSelectedMissionHasNoOwner(false);
      }
    }, [emailFormMissionId]);

    const resetAudiencesFormData = () => {
      audiences.forEach((audience) => {
        onAudienceFormChange(audience, {
          missionRoleDescription: '',
          missionRoleId: '',
        });
      });
    };

    const audienceSelectedCounts = useMemo(
      () =>
        audiences.map((audience) => {
          return {
            label: audience.label,
            count: getAudienceSelectedCount(
              audience,
              audienceTotalCounts,
              validAudiencesFormData.get(audience.label)?.limit,
            ),
          };
        }),
      [audiences, validAudiencesFormData, audienceTotalCounts],
    );

    useEffect(() => {
      if (!sendEmailLoading) {
        setConfirmSendEmailModalOpen(false);
      }
    }, [sendEmailLoading]);

    useEffect(() => {
      if (!sendPreviewLoading) {
        setConfirmPreviewModalOpen(false);
      }
    }, [sendPreviewLoading]);

    useEffect(() => {
      !emailForm.getValues('fromUser') &&
        !emailForm.getValues('mainReplyToUser') &&
        fillFromAndReplyToValuesWithCurrentUser();
    }, [auth.currentUser]);

    const fillFromAndReplyToValuesWithCurrentUser = () => {
      if (!auth.currentUser) {
        return;
      }
      const userObj = {
        email: auth.currentUser.email,
        fullName: auth.currentUser.fullName,
        imageUrl: auth.currentUser.profilePictureURL,
      };
      emailForm.setValue('fromUser', { ...userObj }, {});
      emailForm.setValue('mainReplyToUser', { ...userObj });
    };

    const fillFromAndReplyToValuesWithFormation = () => {
      if (!auth.currentUser) {
        return;
      }
      const currentUserObj = {
        email: auth.currentUser.email,
        fullName: auth.currentUser.fullName,
        imageUrl: auth.currentUser.profilePictureURL,
      };
      const formationUserObj = formationUserObject;
      emailForm.setValue('fromUser', { ...formationUserObj });
      emailForm.setValue('mainReplyToUser', { ...formationUserObj });
      if (
        !additionalReplyToUsersFieldArray.fields.find(
          (field) => field.email === currentUserObj.email,
        )
      ) {
        additionalReplyToUsersFieldArray.append({ ...currentUserObj });
      }
    };

    const addBccAddressIfNotExists = (email: string) => {
      const bccAddresses = emailForm.getValues('bccAddresses') || [];
      if (!bccAddresses.find((bccAddress) => bccAddress.value === email)) {
        bccAddressesFieldArray.append({ value: email });
      }
    };

    const clearBccAddresses = () => {
      bccAddressesFieldArray.fields.forEach((field, idx) => {
        bccAddressesFieldArray.remove(idx);
      });
    };

    const clearCcAddresses = () => {
      ccAddressesFieldArray.fields.forEach((field, idx) => {
        ccAddressesFieldArray.remove(idx);
      });
    };

    useEffect(() => {
      if (
        emailFormEmailType === EmailFromTargeterSendDtoTypeEnum.PlainReachout &&
        !emailForm.getValues('bodySingleRoleMatch') &&
        !emailForm.getValues('bodyMultipleRoleMatch')
      ) {
        Promise.all([
          TargeterEmailService.getTemplateContent(
            auth,
            EmailTemplatesControllerGetTemplateIdEnum.SingleRole,
          ),
          TargeterEmailService.getTemplateContent(
            auth,
            EmailTemplatesControllerGetTemplateIdEnum.MultipleRoles,
          ),
        ]).then(([singleRoleMatchTemplate, multipleRoleMatchTemplate]) => {
          emailForm.setValue('bodySingleRoleMatch', singleRoleMatchTemplate);
          emailForm.setValue(
            'bodyMultipleRoleMatch',
            multipleRoleMatchTemplate,
          );
        });
      }
      // Set the mission description as the body if the email type is rich reachout and the body is empty
      if (
        (emailFormEmailType === EmailFromTargeterSendDtoTypeEnum.RichReachout ||
          emailFormEmailType ===
            EmailFromTargeterSendDtoTypeEnum.RichReachoutV2) &&
        selectedMissionDescription &&
        !emailForm.getValues('body')
      ) {
        emailForm.setValue('body', selectedMissionDescription);
      }
      // Set from and reply-to default values if the email type is rich reachout
      if (
        (emailFormEmailType === EmailFromTargeterSendDtoTypeEnum.RichReachout ||
          emailFormEmailType ===
            EmailFromTargeterSendDtoTypeEnum.PlainReachout ||
          emailFormEmailType ===
            EmailFromTargeterSendDtoTypeEnum.RichReachoutV2) &&
        emailForm.formState.touchedFields['type']
      ) {
        fillFromAndReplyToValuesWithFormation();
      } else if (emailForm.formState.touchedFields['type']) {
        fillFromAndReplyToValuesWithCurrentUser();
      }
      removeDuplicateAdditionalReplyToUsers();
    }, [emailFormEmailType]);

    useEffect(() => {
      if (!auth.currentUser || !emailFormEmailType || !formLoaded) {
        return;
      }
      if (preventFillingDefaultBccAddresses) {
        setPreventFillingDefaultBccAddresses(false);
        return;
      }
      fillDefaultBccSubaddresses();
    }, [auth.currentUser, emailFormEmailType, emailFormMissionId]);

    const fillDefaultBccSubaddresses = () => {
      if (!auth.currentUser) {
        return;
      }
      if (
        emailFormEmailType === EmailFromTargeterSendDtoTypeEnum.CustomTemplated
      ) {
        clearBccSubaddresses();
        addBccAddressIfNotExists(
          addSubaddressToEmail(auth.currentUser.email, 'customtemplated'),
        );
      } else if (
        emailFormEmailType === EmailFromTargeterSendDtoTypeEnum.PlainReachout
      ) {
        clearBccSubaddresses();
        if (emailFormMissionId) {
          addBccAddressIfNotExists(
            addSubaddressToEmail(auth.currentUser.email, emailFormMissionId),
          );
        }
      } else if (
        emailFormEmailType === EmailFromTargeterSendDtoTypeEnum.CustomPlain
      ) {
        clearBccSubaddresses();
        addBccAddressIfNotExists(
          addSubaddressToEmail(auth.currentUser.email, 'customplain'),
        );
      }
    };

    const removeDuplicateAdditionalReplyToUsers = () => {
      additionalReplyToUsersFieldArray.fields.forEach((field, index) => {
        if (
          additionalReplyToUsersFieldArray.fields.findIndex(
            (f) => f.email === field.email,
          ) !== index ||
          field.email === emailForm.getValues('mainReplyToUser')?.email
        ) {
          additionalReplyToUsersFieldArray.remove(index);
        }
      });
    };

    const clearBccSubaddresses = () => {
      const bccAddresses = emailForm.getValues('bccAddresses') || [];
      const updatedBccAddresses = bccAddresses.filter((bccAddress) => {
        return !bccAddress.value.includes('+');
      });
      emailForm.setValue('bccAddresses', updatedBccAddresses);
    };

    const onClickPreviewEmail = () => {
      if (!emailFormEmailType) {
        return;
      }
      emailForm.handleSubmit((data) => {
        const audiencesFormDataError = getAudiencesFormDataError(
          emailFormEmailType,
          validAudiencesFormData,
        );
        if (audiencesFormDataError) {
          setAudienceFormValidationLoading(
            Promise.reject(new Error(audiencesFormDataError)),
          );
          return;
        }
        setValidFormData(data as EmailFormData);
        setConfirmPreviewModalOpen(true);

        let mission: MissionAdminObject | undefined = undefined;

        if (
          emailFormEmailType !== EmailFromTargeterSendDtoTypeEnum.CustomPlain &&
          emailFormEmailType !==
            EmailFromTargeterSendDtoTypeEnum.CustomTemplated
        ) {
          mission = selectedMission;
        }

        const values = emailForm.getValues();
        users.trackTargeterPreviewEmailClicked(
          mission?.mid || '',
          mission?.title || '',
          mission?.status || '',
          values.category as string,
          values.type as string,
          audiences.length > 0 ? audiences.map((a) => a.roleId).join(',') : '',
        );
      })();
    };

    const onClickSendEmail = () => {
      if (!emailFormEmailType) {
        return;
      }

      emailForm.handleSubmit((data) => {
        const audiencesFormDataError = getAudiencesFormDataError(
          emailFormEmailType,
          validAudiencesFormData,
        );
        if (audiencesFormDataError) {
          setAudienceFormValidationLoading(
            Promise.reject(new Error(audiencesFormDataError)),
          );
          return;
        }
        setValidFormData(data as EmailFormData);
        setConfirmSendEmailModalOpen(true);

        let mission: MissionAdminObject | undefined = undefined;

        if (
          emailFormEmailType !== EmailFromTargeterSendDtoTypeEnum.CustomPlain &&
          emailFormEmailType !==
            EmailFromTargeterSendDtoTypeEnum.CustomTemplated
        ) {
          mission = selectedMission;
        }

        const values = emailForm.getValues();
        users.trackTargeterSendEmailClicked(
          mission?.mid || '',
          mission?.title || '',
          mission?.status || '',
          values.category as string,
          values.type as string,
          audiences.length > 0 ? audiences.map((a) => a.roleId).join(',') : '',
        );
      })();
    };

    const onClickCreateCampaign = () => {
      if (
        !emailFormEmailType ||
        emailFormEmailType === EmailFromTargeterSendDtoTypeEnum.CustomPlain ||
        emailFormEmailType === EmailFromTargeterSendDtoTypeEnum.CustomTemplated
      ) {
        setAudienceFormValidationLoading(
          Promise.reject(
            new Error(`Can't create campaign with this email type`),
          ),
        );
        return;
      }
      if (
        Array.from(validAudiencesFormData.values())
          .filter((audienceFormData) => !audienceFormData.disabled)
          .some((v) => !v.limit || isNaN(v.limit) || v.limit < 1)
      ) {
        setAudienceFormValidationLoading(
          Promise.reject(
            new Error(`Audience limit is mandatory for campaign creation`),
          ),
        );
        return;
      }
      emailForm.handleSubmit((data) => {
        const audiencesFormDataError = getAudiencesFormDataError(
          emailFormEmailType,
          validAudiencesFormData,
        );
        if (audiencesFormDataError) {
          setAudienceFormValidationLoading(
            Promise.reject(new Error(audiencesFormDataError)),
          );
          return;
        }
        setValidFormData(data as EmailFormData);
        setConfirmCreateCampaignModalOpen(true);
      })();
    };

    const onClickGenerateEmail = async () => {
      if (!emailFormMissionId || generateEmailLoading === true) {
        return;
      }
      setGenerateEmailLoading(
        TargeterEmailService.generateEmail(auth, emailFormMissionId)
          .then((generatedEmailData) => {
            emailForm.setValue(
              'body',
              `<h3>${generatedEmailData.result.title}</h3> <br/> ${generatedEmailData.result.body}`,
            );
            emailForm.setValue(
              'gptUsageLogId',
              generatedEmailData.gptUsageLogId,
            );
            persistEmailFromTargeterFormSessionStorage(emailForm.getValues());
            return `Email generated successfully`;
          })
          .catch(() => {
            throw new Error(`Could not generate email`);
          }),
      );
    };

    const onClickResetForm = () => {
      emailForm.reset(defaultFormValues);
      setValidAudiencesFormData(new Map());
      clearBccAddresses();
      clearCcAddresses();
      fillFromAndReplyToValuesWithCurrentUser();
      resetAudiencesFormData();
      setSelectedMission(undefined);
      setSelectedMissionDescription('');
      setSelectedMissionRoles([]);
      fillDefaultBccSubaddresses();
      sessionStorage.removeItem('emailFromTargeterForm');
    };

    const onConfirmPreviewEmail = async () => {
      if (!auth.currentUser?.email || !validFormData) {
        return;
      }
      setSendPreviewLoading(
        TargeterEmailService.sendPreviewEmail(
          auth,
          validFormData as EmailFormData,
          await Promise.all(
            enabledAudiences.map(async (audience) => ({
              filters: await getSearchServiceFiltersFromAudience(
                auth,
                audience.filters,
                validFormData.category,
              ),
              recipients: audience.selectedBuilders,
              ...validAudiencesFormData.get(audience.label),
            })),
          ),
        )
          .then(() => {
            setConfirmPreviewModalOpen(false);
            emailForm.setValue('teamArchitectUser', undefined);
            setSelectedMissionHasNoOwner(false);
            return `Email sent successfully`;
          })
          .catch(() => {
            throw new Error(`Could not send preview email`);
          }),
      );
    };

    const onConfirmSendEmail = async () => {
      if (!auth.currentUser?.email || !validFormData) {
        return;
      }
      setSendEmailLoading(
        TargeterEmailService.sendEmail(
          auth,
          validFormData as EmailFormData,
          await Promise.all(
            enabledAudiences.map(async (audience) => ({
              filters: await getSearchServiceFiltersFromAudience(
                auth,
                audience.filters,
                validFormData.category,
              ),
              recipients: audience.selectedBuilders,
              ...validAudiencesFormData.get(audience.label),
            })),
          ),
        )
          .then(async () => {
            return Promise.all([
              selectedMissionHasNoOwner &&
              emailFormTeamArchitectUser?.id &&
              emailFormMissionId
                ? TargeterEmailService.assignOwnerToMission(
                    auth,
                    emailFormMissionId,
                    emailFormTeamArchitectUser.id,
                  )
                : Promise.resolve(),
              emailFormMissionId
                ? TargeterEmailService.setRolesVisible(
                    auth,
                    emailFormMissionId,
                    enabledAudiences
                      .map(
                        (audience) =>
                          validAudiencesFormData.get(audience.label)
                            ?.missionRoleId,
                      )
                      .filter((roleId): roleId is string => !!roleId),
                  )
                : Promise.resolve(),
            ])
              .catch(() => {
                return `Email sent successfully but owner couldn't be assigned to mission`;
              })
              .finally(async () => {
                setConfirmSendEmailModalOpen(false);
                updateMissionPostedDate(
                  enabledAudiences
                    .map(
                      (audience) =>
                        validAudiencesFormData.get(audience.label)
                          ?.missionRoleId,
                    )
                    .filter((roleId): roleId is string => !!roleId),
                );
                emailForm.getValues('gptUsageLogId') &&
                  closeEmailFromTargeterUsageLog({
                    body: emailForm.getValues('body') || '',
                    gptUsageLogId: emailForm.getValues('gptUsageLogId') || '',
                  });
                return `Email sent successfully`;
              });
          })
          .catch(() => {
            throw new Error(`Could not send email`);
          }),
      );
    };

    const onConfirmCreateCampaign = async (
      timeBetweenEmails: number,
      maxNumberOfBuildersPerRole: Record<string, number>,
    ) => {
      if (!auth.currentUser?.email || !validFormData) {
        return;
      }
      setCreateCampaignLoading(
        TargeterEmailService.createCampaign(
          auth,
          validFormData as EmailFormData,
          await Promise.all(
            enabledAudiences.map(async (audience) => ({
              filters: await getSearchServiceFiltersFromAudience(
                auth,
                audience.filters,
                validFormData.category,
              ),
              recipients: audience.selectedBuilders,
              originalAutomatedReachoutPreset: audience.automatedReachoutPreset,
              ...validAudiencesFormData.get(audience.label),
            })),
          ),
          timeBetweenEmails,
          maxNumberOfBuildersPerRole,
        )
          .then(async () => {
            return Promise.all([
              selectedMissionHasNoOwner &&
              emailFormTeamArchitectUser?.id &&
              emailFormMissionId
                ? TargeterEmailService.assignOwnerToMission(
                    auth,
                    emailFormMissionId,
                    emailFormTeamArchitectUser.id,
                  )
                : Promise.resolve(),
              emailFormMissionId
                ? TargeterEmailService.setRolesVisible(
                    auth,
                    emailFormMissionId,
                    enabledAudiences
                      .map(
                        (audience) =>
                          validAudiencesFormData.get(audience.label)
                            ?.missionRoleId,
                      )
                      .filter((roleId): roleId is string => !!roleId),
                  )
                : Promise.resolve(),
            ])
              .catch(() => {
                return `Email sent successfully but owner couldn't be assigned to mission`;
              })
              .finally(async () => {
                setConfirmCreateCampaignModalOpen(false);
                updateMissionPostedDate(
                  enabledAudiences
                    .map(
                      (audience) =>
                        validAudiencesFormData.get(audience.label)
                          ?.missionRoleId,
                    )
                    .filter((roleId): roleId is string => !!roleId),
                );
                emailForm.getValues('gptUsageLogId') &&
                  closeEmailFromTargeterUsageLog({
                    body: emailForm.getValues('body') || '',
                    gptUsageLogId: emailForm.getValues('gptUsageLogId') || '',
                  });
                return `Email sent successfully`;
              });
          })
          .catch((e) => {
            throw new Error(`Could not send email`);
          }),
      );
    };

    const onAudienceFormChange = (
      audience: AudienceData,
      data: Partial<ValidAudienceFormData>,
    ) => {
      setValidAudiencesFormData((audiencesFormData) => {
        const newAudiencesFormData = new Map(audiencesFormData);
        const currentAudienceFormData = audiencesFormData.get(audience.label);
        if (
          data.missionRoleId &&
          currentAudienceFormData?.missionRoleId !== data.missionRoleId
        ) {
          data.missionRoleDescription = (selectedMissionRoles || []).find(
            (selectedMissionRole) =>
              selectedMissionRole.rid === data.missionRoleId,
          )?.headline;
        }
        newAudiencesFormData.set(audience.label, {
          ...currentAudienceFormData,
          ...(data as ValidAudienceFormData),
        });
        return newAudiencesFormData;
      });
    };

    const onClickOnSelectAll = (audience: AudienceData) => {
      onAudienceFormChange(audience, { limit: undefined });
      onClickOnSelectAllAudience && onClickOnSelectAllAudience(audience);
    };

    return (
      <TargeterEmailLayout
        showCreateCampaignButton={showCreateCampaignButton}
        onClickCreateCampaign={onClickCreateCampaign}
        editAudienceDisabled={
          sendEmailLoading === true ||
          sendPreviewLoading === true ||
          generateEmailLoading === true
        }
        sidebarElement={
          <Sidebar
            audiences={audiences}
            audienceTotalCounts={audienceTotalCounts}
            audienceSelectedCounts={audienceSelectedCounts}
            audienceFormData={validAudiencesFormData}
            onAudienceFormDataChange={(
              audience,
              data: Partial<ValidAudienceFormData>,
            ) => onAudienceFormChange(audience, data)}
            onClickOnSelectAll={(audience) => onClickOnSelectAll(audience)}
            showMissionFields={
              emailFormEmailType ===
                EmailFromTargeterSendDtoTypeEnum.RichReachout ||
              emailFormEmailType ===
                EmailFromTargeterSendDtoTypeEnum.RichReachoutV2 ||
              emailFormEmailType ===
                EmailFromTargeterSendDtoTypeEnum.PlainReachout
            }
            missionRoleOptions={missionRoleOptions}
          ></Sidebar>
        }
        onClickOnEditAudience={onClickOnEditAudience}
        onClickPreviewEmail={onClickPreviewEmail}
        onClickSendEmail={onClickSendEmail}
        onClickResetForm={onClickResetForm}
      >
        <EmailForm
          ccAddressesFieldArray={ccAddressesFieldArray}
          bccAddressesFieldArray={bccAddressesFieldArray}
          showTeamArchitectField={
            selectedMissionHasNoOwner && !!emailFormMissionId
          }
          additionalReplyToUsersFieldArray={additionalReplyToUsersFieldArray}
          numAudiences={audiences.length}
          form={emailForm}
          additionalFromAndReplyToEmails={[FORMATION_EMAIL]}
          onClickOnGenerate={onClickGenerateEmail}
          isGenerating={generateEmailLoading === true}
        ></EmailForm>
        <ConfirmModal
          open={confirmPreviewModalOpen}
          cancelDisabled={sendPreviewLoading === true}
          actionLoading={sendPreviewLoading === true}
          onClose={() => setConfirmPreviewModalOpen(false)}
          onConfirm={onConfirmPreviewEmail}
          title={`Send a notification preview?`}
          description={`This will send a preview email to ${auth.currentUser?.email}`}
          actionLabel="Send preview email"
        ></ConfirmModal>
        <ConfirmModal
          cancelDisabled={sendEmailLoading === true}
          actionLoading={sendEmailLoading === true}
          actionDisabled={
            totalAudienceCount === 0 ||
            sendEmailLoading === true ||
            totalAudienceCountLoading
          }
          open={confirmSendEmailModalOpen}
          onClose={() => setConfirmSendEmailModalOpen(false)}
          onConfirm={onConfirmSendEmail}
          title={`Are you sure you want to send an email?`}
          description={
            <>
              <p>
                This will send an email to{' '}
                {totalAudienceCountLoading ? (
                  <Spinner size={12} color="#333" />
                ) : (
                  <strong>{totalAudienceCount}</strong>
                )}{' '}
                users from <strong>{enabledAudiences.length}</strong> audience
                {enabledAudiences.length > 1 ? 's' : ''}
              </p>
              {selectedMissionHasNoOwner &&
                emailFormTeamArchitectUser?.id &&
                emailFormMissionId && (
                  <p>
                    This will assign{' '}
                    <strong>
                      {emailFormTeamArchitectUser?.fullName ||
                        emailFormTeamArchitectUser?.email}
                    </strong>{' '}
                    as owner for this mission
                  </p>
                )}
              {emailFormSignatureUser &&
                auth.currentUser &&
                (emailFormEmailType ===
                  EmailFromTargeterSendDtoTypeEnum.RichReachout ||
                  emailFormEmailType ===
                    EmailFromTargeterSendDtoTypeEnum.RichReachoutV2) &&
                emailFormSignatureUser.email !== auth.currentUser.email && (
                  <p>
                    <strong>Warning:</strong> You are sending this email with{' '}
                    <strong>
                      {emailFormSignatureUser?.fullName ||
                        emailFormSignatureUser?.email}
                    </strong>{' '}
                    signature
                  </p>
                )}
            </>
          }
          actionLabel="Send email"
        ></ConfirmModal>
        <CreateCampaignModal
          mission={selectedMission}
          audiences={enabledAudiences}
          confirmCreateCampaignModalOpen={confirmCreateCampaignModalOpen}
          setConfirmCreateCampaignModalOpen={setConfirmCreateCampaignModalOpen}
          onConfirmCreateCampaign={onConfirmCreateCampaign}
          createCampaignLoading={!!createCampaignLoading}
          totalAudienceCount={totalAudienceCount}
          totalAudienceCountLoading={totalAudienceCountLoading}
          selectedMissionHasNoOwner={selectedMissionHasNoOwner}
          emailFormTeamArchitectUser={emailFormTeamArchitectUser}
          emailFormSignatureUser={emailFormSignatureUser}
          emailFormEmailType={emailFormEmailType}
          currentUserEmail={auth.currentUser?.email}
        />
        <LoadingIndicator
          loading={
            sendEmailLoading || sendPreviewLoading || createCampaignLoading
          }
          loadingMsg={
            sendEmailLoading
              ? LOADING_MSG_SENDING_EMAIL
              : sendPreviewLoading
              ? LOADING_MSG_SENDING_PREVEIW_EMAIL
              : LOADING_MSG_CREATE_CAMPAIGN
          }
          successMsg={
            sendEmailLoading
              ? SUCCESS_MSG_SEND_EMAIL
              : sendPreviewLoading
              ? SUCCESS_MSG_SEND_PREVEIW_EMAIL
              : SUCCESS_MSG_CREATE_CAMPAIGN
          }
        />
        <LoadingIndicator loading={audienceFormValidationLoading} />
        <LoadingIndicator
          loading={generateEmailLoading}
          loadingMsg={GENERATING_EMAIL_LOADING_MSG}
        />
      </TargeterEmailLayout>
    );
  },
);

const getAudienceSelectedCount = (
  audience: AudienceData,
  audienceTotalCounts: AudienceTotalCount[],
  limit?: number,
) => {
  if (audience.selectedBuilders.length > 0) {
    return limit
      ? Math.min(audience.selectedBuilders.length, limit)
      : audience.selectedBuilders.length;
  } else {
    const audienceTotalCount =
      audienceTotalCounts.find(
        (audienceTotalCount) => audienceTotalCount.label === audience.label,
      )?.count || 0;
    return limit ? Math.min(audienceTotalCount, limit) : audienceTotalCount;
  }
};

const getSearchServiceFiltersFromAudience = async (
  auth: AuthStore,
  roleFilter: RoleFilter,
  emailCategory: EmailFromTargeterSendDtoEmailCategoryEnum,
) => {
  const criteria = mapTargeterApiRequest(roleFilter);
  const searchServiceFilters = (
    await queryParametersToSearchRequestBody({
      auth,
      criteria,
      pageSize: 1000,
    })
  ).filter;
  return {
    ...searchServiceFilters,
    requiredNotificationCategories: uniq([
      ...(searchServiceFilters.requiredNotificationCategories || []),
      emailCategory,
    ]),
    requireAllSelectedNotificationCategories: true,
  };
};

/**
 * Returns the first error message for the given audience form data, or undefined if there are no errors
 */
const getAudiencesFormDataError = (
  emailType: EmailFromTargeterSendDtoTypeEnum,
  audiencesFormData: Map<string, ValidAudienceFormData>,
): string | undefined => {
  if (
    emailType === EmailFromTargeterSendDtoTypeEnum.CustomPlain ||
    emailType === EmailFromTargeterSendDtoTypeEnum.CustomTemplated
  ) {
    return undefined;
  }
  if (!audiencesFormData.size) {
    return 'Mission role and mission description are required';
  }
  for (const [audienceLabel, audienceFormData] of audiencesFormData) {
    if (audienceFormData.disabled) {
      continue;
    }
    if (
      emailType === EmailFromTargeterSendDtoTypeEnum.RichReachout ||
      emailType === EmailFromTargeterSendDtoTypeEnum.PlainReachout
    ) {
      if (!audienceFormData.missionRoleId) {
        return `Please select a mission role for "${audienceLabel}"`;
      }
      if (!audienceFormData.missionRoleDescription) {
        return `Please add a role description for "${audienceLabel}"`;
      }
    }
  }
  return undefined;
};
