import { DateISOString } from '../misc';
import { UserBadge, UserId } from '../UserObject';
import RoleCategoryObject from '../RoleCategory';
import { TalentCategory } from '../TalentCategories';
import { Vetter, VetterId } from '../vetter';
import { VettingProcessAudit } from '../vetting-process-audit';
import {
  VettingProcessPreVettingForm,
  VettingProcessPreVettingFormOld,
} from './pre-vetting';
import { VettingProcessStatus } from './status';
import {
  VettingProcessFeedback,
  VettingProcessFeedbackScores,
} from './feedback';
import { VettingProcessPostEvaluationSurvery } from './post-evaluation-survey';
import {
  AdminInterviewInviteEmail,
  EmailThreadMetadata,
  InterviewInviteEmail,
  ReachoutEmail,
  VetterNotified,
} from './emails';
import { VettingProcessFeedbackAdditionalMaterial } from './feedback-additional-material';
import TimezoneObject from '@a_team/models/src/TimezoneObject';
import { VettingProcessFeedbackV2 } from './feedbackV2';

export type VettingProcessId = string;

export enum VettingType {
  SelectionTeam = 'selectionTeam',
  InternalNonTechnical = 'internalNonTechnical',
  InternalTechnical = 'internalTechnical',
}

export const vettingTypeToStatusMap: Record<VettingType, string> = {
  [VettingType.SelectionTeam]: 'Selection Team',
  [VettingType.InternalNonTechnical]: 'Internal Non-technical',
  [VettingType.InternalTechnical]: 'Internal Technical',
};

export interface VettingProcessUser {
  id: UserId;
  email: string;
  fullName: string;
  firstName: string;
  profilePictureURL: string;
  profileURL: string;
  roleCategory: RoleCategoryObject | null;
  badges: UserBadge[];
  linkedIn?: {
    username: string;
  };
  websites?: string[];
  phoneNumber?: string;
  isNewEvaluation?: boolean;
  timezone?: TimezoneObject;
}

export interface VettingInterviewerRoleCategory {
  id: string;
  name: string;
  group: string;
}

export interface VettingInterviewerSkill {
  id: string;
  name: string;
}

export interface VettingInterviewer {
  vetterId: VetterId;
  uid: UserId;
  email: string;
  fullName: string;
  username: string;
  type: string;
  availableSlots: number;
  slotsInPeriods: number[];
  roleCategories: VettingInterviewerRoleCategory[];
  skills: VettingInterviewerSkill[];
  interviewsLast3Weeks?: number;
}

export interface AdminVettingProcessUser extends VettingProcessUser {
  scores: Partial<VettingProcessFeedbackScores>;
}

export interface BasicVettingProcess {
  id: VettingProcessId;
  vetter?: Vetter;
  user: VettingProcessUser;
  status: VettingProcessStatus;
  category?: TalentCategory;
  vettingType: VettingType;
  hasVetterAccepted?: boolean;
  interviewInviteEmails?: InterviewInviteEmail[];
  interviewDate?: DateISOString;
  /** @deprecated */
  preVetting?: VettingProcessPreVettingFormOld;
  preVettingForm?: VettingProcessPreVettingForm;
  feedback?: VettingProcessFeedback;
  feedbackV2?: VettingProcessFeedbackV2;
  postEvaluationSurvey?: VettingProcessPostEvaluationSurvery;
  preVettingFormNonce?: string;
  calComBookingUid?: string;
  createdAt?: DateISOString;
}

export enum OptOutReasonTypes {
  DidNotShowUpToTheCall = 'didNotShowUpToTheCall',
  DidNotReplyToEmails = 'didNotReplyToEmails',
  Other = 'other',
}

export const optOutReasonTypeToLabelMap: Record<OptOutReasonTypes, string> = {
  [OptOutReasonTypes.DidNotShowUpToTheCall]: "Didn't show up to the call",
  [OptOutReasonTypes.DidNotReplyToEmails]: "Didn't reply to emails",
  [OptOutReasonTypes.Other]: 'Other (specify custom reason)',
};

export interface VetterOptedOut {
  vetter: {
    uid: UserId;
    email: string;
    fullName: string;
  };
  date: Date;
  reason: string;
}

export interface UserOptedOut {
  id: string;
  wasMarkedByAdmin: boolean;
  markedBy?: {
    uid: UserId;
    fullName: string;
  };
  reason: string;
  date?: Date;
}

export interface ContactOwner {
  uid: UserId;
  email: string;
  fullName: string;
  profilePictureURL: string;
}

export interface AdminVettingProcess extends BasicVettingProcess {
  user: AdminVettingProcessUser;
  vetterOptedOut?: VetterOptedOut[];
  userOptedOut?: {
    admin?: UserOptedOut;
    vetter?: UserOptedOut;
  };
  /** @deprecated */
  builderOptedOut?: string;
  vetterNotified?: VetterNotified[];
  reachoutEmails?: ReachoutEmail[];
  reachoutEmailThreadMetadata?: EmailThreadMetadata;
  interviewInviteEmails?: AdminInterviewInviteEmail[];
  contactOwner?: ContactOwner;
  recordingDownloadLinks?: string[];
  transcriptJobIds?: string[];
  vettingScheduleUrl?: string;
}

export type VettingProcess = BasicVettingProcess | AdminVettingProcess;

export interface AdminVettingProcessWithAudit extends AdminVettingProcess {
  audit: VettingProcessAudit[];
  feedbackAdditionalMaterials?: VettingProcessFeedbackAdditionalMaterial[];
}

export const DEFAULT_CONTACT_OWNER_EMAIL = 'tanya@a.team';

export enum VettingFormVariant {
  SoftwareEngineering = 'Software Engineering',
  ProductManagement = 'Product Management',
  ProjectManagement = 'Project Management',
  Design = 'Design',
  Marketing = 'Marketing',
  Operations = 'Operations',
  DataScience = 'Data Science',
}

export const AUTOMATIC_EVALUATION_INVITE_ALLOWED_CATEGORIES = [
  'software-engineering',
  'product-management',
  'project-management',
  'design',
  'data-science',
  'marketing',
  'operations',
];

export const AUTOMATIC_EVALUATION_INVITE_ALLOWED_CATEGORIES_SELECTION_TEAM = [
  'design',
];

export function getVettingFormVariant(
  category?: TalentCategory,
): VettingFormVariant {
  const variant = Object.values(VettingFormVariant).find(
    (variant) => category?.name === variant,
  );

  // SoftwareEngineering is the default
  return variant || VettingFormVariant.SoftwareEngineering;
}

export function getVettingType(talentCategory: string): VettingType {
  if (
    AUTOMATIC_EVALUATION_INVITE_ALLOWED_CATEGORIES_SELECTION_TEAM.includes(
      talentCategory,
    )
  ) {
    return VettingType.SelectionTeam;
  }

  return VettingType.InternalTechnical;
}
