import React, { memo, useCallback } from 'react';
import { Skill } from '.';
import { createUseStyles } from 'react-jss';
import {
  BorderRadius,
  Colors,
  FontSizes,
  Icon,
  IconType,
  Spacing,
  Tag,
} from '@ateams/components';
import clsx from 'clsx';
import { FieldTextarea } from '../general/FieldTextarea';
import { ScoreSelection } from '../general/ScoreSelection';
import { useCommonStyles } from '../commonStyles';
import TooltipWrapped from '@src/components/TooltipWrapped';
import { useFormContext } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form';
import { SkillError } from '../../VettingFeedbackFormV2';

const useStyles = createUseStyles({
  skillTag: {
    borderRadius: BorderRadius.medium,
    padding: Spacing.small,
    whiteSpace: 'nowrap',
    cursor: 'pointer',
  },
  requiredSkillTag: {
    backgroundColor: Colors.infoLight,
  },
  skillText: {
    fontSize: FontSizes.small,
    color: Colors.dark,
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    gap: Spacing.small,
    paddingLeft: Spacing.xsmall,
    paddingRight: Spacing.xsmall,
  },
  closeIcon: {
    cursor: 'pointer',
    fontSize: FontSizes.xsmall,
  },
});

export interface SkillItemProps {
  skill: Skill;
  onChange: (skill: Skill) => void;
  onDelete: () => void;
  disabled: boolean;
}

export const SkillItem = memo(
  ({ skill, onChange, onDelete, disabled }: SkillItemProps) => {
    const commonStyles = useCommonStyles();
    const styles = useStyles();
    const {
      formState: { errors },
      clearErrors,
    } = useFormContext();

    const skillsErrors = errors?.skills as
      | FieldErrors<Record<string, SkillError>>
      | undefined;
    const skillNotesError = skillsErrors?.[skill.id]?.notes?.message;

    const handleScoreChange = useCallback(
      (score: number) => {
        const newScore = skill.score !== score ? score : 0;
        onChange({ ...skill, score: newScore });
      },
      [skill.id, onChange],
    );

    const handleNotesChange = useCallback(
      (notes: string) => {
        onChange({ ...skill, notes });
      },
      [skill.id, onChange],
    );

    return (
      <div className={commonStyles.skill}>
        <div>
          <TooltipWrapped
            key={skill.id}
            title={
              skill.required
                ? 'Role required skill'
                : skill.addedFromInterviewer
                ? 'Interviewer added skill'
                : 'Builder added skill'
            }
          >
            <Tag
              className={clsx(
                styles.skillTag,
                skill.required && styles.requiredSkillTag,
              )}
            >
              <div className={styles.skillText}>
                <div>{skill.name}</div>
                {skill.addedFromInterviewer && (
                  <Icon
                    type={IconType.Close}
                    className={styles.closeIcon}
                    onClick={onDelete}
                  />
                )}
              </div>
            </Tag>
          </TooltipWrapped>
        </div>
        <ScoreSelection
          score={skill.score}
          onChange={handleScoreChange}
          disabled={disabled}
        />
        <FieldTextarea
          value={skill.notes}
          onChange={handleNotesChange}
          placeholder="Add a comment..."
          showLeftBorder={false}
          minLength={20}
          compactField
          error={skillNotesError as string}
          name={`skills.${skill.id}`}
          clearErrors={clearErrors}
          disabled={disabled}
        />
      </div>
    );
  },
);
