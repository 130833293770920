import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { createUseStyles } from 'react-jss';
import { format } from 'date-fns';
import { find, round } from 'lodash';
import {
  BorderColors,
  BorderRadius,
  Colors,
  FontSizes,
  FontWeights,
  Icon,
  IconType,
  Paddings,
  formatLocation,
  Button,
} from '@ateams/components';
import { DateISOString } from '@a_team/models/dist/misc';
import { AdminNotesScore } from '@a_team/models/dist/AdminNotesObject';
import { AvailableType } from '@a_team/models/dist/AvailabilityObject';
import {
  UserTalentSkillAssignmentData,
  UserTalentSkillAssignment,
} from '@a_team/models/dist/TalentCategories';
import { SkillTargeterUserObject } from '@a_team/models/dist/TeamGraphObject';
import { RoleFilter } from '@src/components/TeamGraphBuilderQuery';
import {
  AccentScoreOptions,
  EnglishLevelOptions,
  ExpertiseScoreOptions,
  InteractionExperienceOptions,
} from '@src/components/ScoreInput/Scores';
import { Card } from '@ateams/components';
import UserAvatar from '@src/components/UserAvatar';
import MatchContainer from './MatchContainer';
import UserBadges from '@src/components/UserBadges';
import { MatchesDetailsModal } from '@src/views/SkillTargeter/TargeterUserCardView/MatchesDetailsModal';
import cx from 'classnames';
import { useQueryUserReviews } from '@src/rq/userReview';
import {
  Checkbox,
  Icon as UiIcon,
  Modal,
  ModalWidth,
  Typography,
} from '@a_team/ui-components';
import Recommendation from '@src/views/Profile/Main/Recommendations/Recommendation';
import { Projects } from '@src/views/SkillTargeter/TargeterUserCardView/Projects';
import { useGetUserExperiencesByUserId } from '@src/rq/experiences';
import { ExperienceId } from '@a_team/models/dist/ExperienceObject';
import ViewProjectModalV2 from '@src/views/Profile/Main/Projects/ViewProjectModalV2';
import { ExistingProject } from '@src/stores/Profile/models';
import { skillToExpertise } from '@src/helpers/expertise';

interface Props {
  user: SkillTargeterUserObject;
  selectBuilder: () => void;
  hideBuilder?: () => void;
  filter?: RoleFilter;
  selected?: boolean;
  duplicated?: boolean;
  onClick?: () => void;
  showDetails?: boolean;
  hourlyRateComponent?: ReactNode;
  blurbComponent?: ReactElement;
  skillsComponent?: ReactElement;
  showRecommendations?: boolean;
  onRecommendationsChange?: (recommendations: string[]) => void;
  showProjects?: boolean;
  onProjectsChange?: (projects: string[]) => void;
}

const useStyles = createUseStyles({
  listItem: {
    listStyle: 'none',
    margin: '0 0 1.5em 0',
    cursor: 'pointer',
    maxWidth: 650,
  },
  card: (props: Props) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    boxShadow: `5px 5px 5px ${Colors.regularLight}`,
    overflow: 'visible',
    background: props.duplicated
      ? Colors.dangerLight
      : props.selected
      ? Colors.primaryVeryLight
      : Colors.backgroundWhite,
    border: props.duplicated
      ? `1px solid ${Colors.danger}`
      : props.selected
      ? `1px solid ${Colors.primaryLight}`
      : `1px solid transparent`,
  }),
  userTitleContainer: {
    display: 'flex',
    padding: '1em',
    justifyContent: 'space-between',
  },
  userTitles: {
    paddingLeft: '1em',
    alignSelf: 'flex-end',
  },
  userInfoContainer: {
    display: 'flex',
    padding: '1em',
    paddingLeft: '2em',
    marginLeft: 46,
    justifyContent: 'space-between',
  },
  userInfoColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  userDetail: {
    fontSize: 14,
    minHeight: 24,
  },
  userDetailDanger: {
    color: Colors.danger,
  },
  viewDetails: {
    whiteSpace: 'nowrap',
    alignSelf: 'flex-end',
    '&:hover': {
      color: Colors.primary,
      cursor: 'pointer',
    },
  },
  starIconContainer: {
    width: 32,
    height: 32,
    position: 'relative',
    '& > i': {
      position: 'absolute',
      top: 0,
      right: 0,
    },
  },
  estimatedAvailability: {
    color: Colors.danger,
  },
  hideButton: {
    height: 32,
    border: `1px solid ${BorderColors.lighter}`,
    background: Colors.transparent,
    color: Colors.dark,
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.semiBold,
    borderRadius: BorderRadius.small,
    paddingLeft: Paddings.small,
    paddingRight: Paddings.small,
    cursor: 'pointer',
  },
  actionButtonContainer: {
    display: 'flex',
    gap: 8,
  },
});

const formatMatches = (
  matches: SkillTargeterUserObject['matchesOn'],
): string => {
  if (!matches) return '';

  const matchList: string[] = [];
  (matches.aboutMe || matches?.textAboutMe) && matchList.push('About me');
  (matches.adminNotes || matches?.textAdminNotes) &&
    matchList.push('Admin notes');
  (matches.experiences ||
    matches?.textJobExperiencesJobRole ||
    matches?.textJobExperiencesName ||
    matches?.textProjectExperiencesJobRole ||
    matches?.textProjectExperiencesTitle ||
    matches?.textProjectExperiencesDescription) &&
    matchList.push('Experiences');
  (matches.missionApplications || matches?.textMissionApplicationPitches) &&
    matchList.push('Applications');
  (matches.email || matches?.textEmail || matches?.textDisplayEmail) &&
    matchList.push('Email');
  (matches.blurbs || matches?.textBlurbs) && matchList.push('Proposal Blurbs');
  (matches?.textVettingDetailsSummary ||
    matches?.textVettingDetailsZeroToOne ||
    matches?.textVettingDetailsPeopleManaged) &&
    matchList.push('Vetting Feedback');
  matches.customTags && matchList.push('Custom Tags');
  matches.textTranscripts && matchList.push('Transcripts');
  matches.textResume && matchList.push('Resume');
  matches.textTimesheetSummaries && matchList.push('Timesheet Summaries');
  matches.textSuggestedJobExperiencesJobRole &&
    matchList.push('Suggested Experiences');

  return matchList.join(' | ');
};

const formatAvailability = (
  availability: SkillTargeterUserObject['availability'],
): string => {
  switch (availability?.type) {
    case AvailableType.NotAvailable:
      return `Not Available`;
    case AvailableType.Now:
      return `Available ${availability.weeklyHoursAvailable} hours weekly`;
    case AvailableType.FutureDate:
      // eslint-disable-next-line no-case-declarations
      const availabilityDate = new Date(
        availability.availableFrom as DateISOString,
      );
      return availabilityDate.getTime() < Date.now()
        ? `Available ${availability.weeklyHoursAvailable} hours weekly`
        : `Available ${format(availabilityDate, 'MMM d')}, for ${
            availability.weeklyHoursAvailable
          } hours weekly`;
    default:
      return `Unknown Availability`;
  }
};

const hasScore = (score?: AdminNotesScore): boolean => Number.isInteger(score);

const findMatchingSkills = (
  skillQuery: string[],
  skills: UserTalentSkillAssignment[],
): UserTalentSkillAssignment[] => {
  return skillQuery
    .map((skill: string) => skill.split('-')[0])
    .map((skill: string) =>
      find(skills, ({ talentSkillId }) => talentSkillId === skill),
    )
    .filter(
      (
        skill: UserTalentSkillAssignment | undefined,
      ): skill is UserTalentSkillAssignment => !!skill,
    );
};

const getSkills = (
  filter?: RoleFilter,
  skills?: UserTalentSkillAssignmentData,
): {
  requiredSkills: UserTalentSkillAssignment[];
  preferredSkills: UserTalentSkillAssignment[];
} => {
  if (!filter) return { requiredSkills: [], preferredSkills: [] };

  const allSkills: UserTalentSkillAssignment[] = [
    ...(skills?.mainTalentSkills ?? []),
    ...(skills?.additionalTalentSkills ?? []),
  ];

  return {
    requiredSkills: findMatchingSkills(filter.requiredSkills ?? [], allSkills),
    preferredSkills: findMatchingSkills(
      filter.preferredSkills ?? [],
      allSkills,
    ),
  };
};

const TargeterUserCard = (props: Props): ReactElement => {
  const {
    user,
    filter,
    selected,
    selectBuilder,
    hourlyRateComponent,
    blurbComponent,
    skillsComponent,
    showDetails = true,
    showRecommendations = false,
    onRecommendationsChange,
    showProjects,
    onProjectsChange,
  } = props;
  const styles = useStyles(props);
  const [matchesDetailsModalOpen, setMatchesDetailsModalOpen] = useState(false);
  const [reviewsModalOpen, setReviewsModalOpen] = useState(false);
  const [projectModalOpen, setProjectModalOpen] = useState(false);
  const [selectedReviews, setSelectedReviews] = useState<string[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<ExperienceId[]>([]);
  const [openedProjectId, setOpenedProjectId] = useState<ExperienceId | null>(
    null,
  );
  const { data: reviews, isLoading: loadingReviews } = useQueryUserReviews({
    uid: user.uid,
    includeAllReviews: false,
    enabled: showRecommendations,
  });
  const { data: userExperiences } = useGetUserExperiencesByUserId({
    userId: user.uid,
    enabled: true,
  });

  const openedProject = userExperiences?.projects.find(
    (p) => p.eid === openedProjectId,
  );

  useEffect(() => {
    if (showRecommendations && reviews && reviews.length > 0) {
      setSelectedReviews(reviews.map((r) => r.id));
      onRecommendationsChange &&
        onRecommendationsChange(reviews.map((r) => r.id));
    }
  }, [reviews, showRecommendations]);

  useEffect(() => {
    if (
      showProjects &&
      userExperiences &&
      userExperiences.projects.length > 0
    ) {
      const sliced = userExperiences.projects.slice(0, 3).map((p) => p.eid);
      setSelectedProjects(sliced);
      onProjectsChange && onProjectsChange(sliced);
    }
  }, [userExperiences?.projects, showProjects]);

  const matchesOn: string = formatMatches(user.matchesOn);
  const availability: string = formatAvailability(user.availability);
  const location = user.location ? formatLocation(user.location) : null;
  const defaultHourlyRateRange =
    !user.rateRange || (!user.rateRange.min && user.rateRange.max === 1000);
  const userMaxRate =
    !user?.rateRange?.max && user?.rateRange?.max !== 0
      ? 'N/A'
      : `$${round(user?.rateRange?.max, 0)}`;
  const userMinRate =
    !user?.rateRange?.min && user?.rateRange?.min !== 0
      ? 'N/A'
      : `$${round(user?.rateRange?.min, 0)}`;

  const role =
    user.talentProfile?.talentSpecializations?.mainTalentSpecialization?.name ??
    user.roleCategory?.title;

  const skills = getSkills(filter, user.talentProfile?.talentSkills);

  const expertiseScore = hasScore(user.expertiseScore)
    ? ExpertiseScoreOptions.find(({ value }) => value === user.expertiseScore)
        ?.label
    : undefined;

  const interactionExperienceScore = hasScore(user.interactionExperienceScore)
    ? InteractionExperienceOptions.find(
        ({ value }) => value === user.interactionExperienceScore,
      )?.label
    : undefined;

  const englishScore = hasScore(user.englishScore)
    ? EnglishLevelOptions.find(({ value }) => value === user.englishScore)
        ?.label
    : undefined;

  const accentScore = hasScore(user.accentScore)
    ? AccentScoreOptions.find(({ value }) => value === user.accentScore)?.label
    : undefined;

  const formattedEstimatedAvailability: string = useMemo(() => {
    if (user.estimatedAvailability || user.estimatedAvailability === 0) {
      return `Est Availability ${user.estimatedAvailability} hours weekly`;
    }
    return '';
  }, []);

  const toggleHide = (e: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();
    props.hideBuilder?.();
  };

  return (
    <li className={styles.listItem}>
      <Card className={styles.card} onClick={props.onClick}>
        <div className={styles.userTitleContainer}>
          <div style={{ display: 'flex' }}>
            <a
              data-testing-id={'targeter-result-card-user-link'}
              href={`/${user.username}`}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              <UserAvatar
                src={user.profilePictureURL}
                badges={user.badges}
                size={46}
              />
            </a>
            <div className={styles.userTitles}>
              <div style={{ display: 'flex', gap: 8 }}>
                <div style={{ fontWeight: 'bold' }}>{user.fullName}</div>
                <UserBadges
                  badges={user.badges}
                  communityBadges={user.communityBadges}
                  expandOnHover
                />
              </div>
              <div className={styles.userDetail}>
                {role} {user.emailNotificationsBlocked && '• Suppressed email'}
              </div>
              {user.linkedIn && (
                <a
                  href={user.linkedIn.profileUrl}
                  target="_blank"
                  rel="noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  <Icon
                    type={IconType.LinkedIn}
                    style={{ cursor: 'pointer' }}
                  />
                </a>
              )}
            </div>
          </div>
          <div className={styles.actionButtonContainer}>
            {props.hideBuilder && (
              <button className={styles.hideButton} onClick={toggleHide}>
                Hide
              </button>
            )}
            <div className={styles.starIconContainer}>
              <Icon
                style={{ visibility: selected ? 'visible' : 'hidden' }}
                size="medium"
                type={IconType.Star}
                onClick={(e) => {
                  e?.stopPropagation();
                  selectBuilder();
                }}
              />
              <Icon
                style={{ visibility: selected ? 'hidden' : 'visible' }}
                size="medium"
                type={IconType.EmptyStar}
                onClick={(e) => {
                  e?.stopPropagation();
                  selectBuilder();
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.userInfoContainer}>
          <div className={styles.userInfoColumn} style={{ minWidth: '50%' }}>
            <div className={styles.userDetail}>{availability}</div>
            {user.estimatedAvailability ? (
              user.estimatedAvailability <
              (user.availability?.weeklyHoursAvailable || 40) ? (
                <div
                  className={cx(
                    styles.userDetail,
                    styles.estimatedAvailability,
                  )}
                >
                  {formattedEstimatedAvailability}
                </div>
              ) : (
                <div className={styles.userDetail}>
                  {formattedEstimatedAvailability}
                </div>
              )
            ) : null}
            <div
              className={cx(
                styles.userDetail,
                user.emailNotificationsBlocked && styles.userDetailDanger,
              )}
            >
              {user.email}
            </div>
            {location && <div className={styles.userDetail}>{location}</div>}
            {!defaultHourlyRateRange &&
              (userMinRate || userMaxRate) &&
              !(blurbComponent || skillsComponent) &&
              (hourlyRateComponent || (
                <div className={styles.userDetail}>
                  {userMinRate === userMaxRate
                    ? `Hourly Rate: ${userMinRate}`
                    : `Hourly Rate: ${userMinRate} - ${userMaxRate}`}
                </div>
              ))}
          </div>
          <div className={styles.userInfoColumn} style={{ flexGrow: 1 }}>
            {Number.isInteger(expertiseScore) && (
              <div className={styles.userDetail}>
                {`Expertise: ${expertiseScore}`}
              </div>
            )}
            {Number.isInteger(interactionExperienceScore) && (
              <div className={styles.userDetail}>
                {`Interaction XP: ${interactionExperienceScore}`}
              </div>
            )}
            {Number.isInteger(englishScore) && (
              <div className={styles.userDetail}>
                {`English: ${englishScore}`}
              </div>
            )}
            {Number.isInteger(accentScore) && (
              <div className={styles.userDetail}>
                {`Accent: ${accentScore}`}
              </div>
            )}
          </div>
        </div>

        {(hourlyRateComponent || blurbComponent || skillsComponent) && (
          <div
            style={{
              margin: 15,
              background: '#fff',
              border: '1px solid #FFCBAE',
              padding: 16,
              borderRadius: 4,
              display: 'flex',
              flexDirection: 'column',
              gap: 24,
            }}
          >
            {hourlyRateComponent && hourlyRateComponent}
            {blurbComponent && blurbComponent}
            {skillsComponent && skillsComponent}
            {showRecommendations && (
              <div>
                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                  <UiIcon size="md" name="star" />
                  Recommendations from past A.Team missions:
                </div>
                <Button
                  color={'backgroundDark'}
                  width={'default'}
                  size={'xsmall'}
                  style={{
                    padding: 8,
                    border: '1px solid #C0C0C0',
                    marginTop: 8,
                  }}
                  squared
                  disabled={!loadingReviews && reviews?.length === 0}
                  loading={loadingReviews}
                  onClick={() => setReviewsModalOpen(true)}
                >
                  {!loadingReviews && reviews?.length === 0
                    ? 'No recommendations'
                    : selectedReviews?.length
                    ? `${selectedReviews?.length} recommendation selected`
                    : 'Select recommendations'}
                </Button>
              </div>
            )}
            {showProjects && (
              <Projects
                selectedProjects={selectedProjects}
                allProjects={userExperiences?.projects || []}
                onChange={(item, selected) => {
                  const updatedProjects = selected
                    ? [...selectedProjects, item]
                    : selectedProjects.filter((p) => p !== item);
                  setSelectedProjects(updatedProjects);
                  onProjectsChange && onProjectsChange(updatedProjects);
                }}
                onDetailsClick={(val) => {
                  setOpenedProjectId(val);
                  setProjectModalOpen(true);
                }}
              />
            )}
          </div>
        )}
        {showDetails && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: Colors.backgroundMedium,
              padding: '0.5em 1em',
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setMatchesDetailsModalOpen(true);
            }}
          >
            <MatchContainer
              matchesOn={matchesOn}
              requiredSkills={skills.requiredSkills}
              preferredSkills={skills.preferredSkills}
            />
            <div className={styles.viewDetails}>View details</div>
          </div>
        )}
      </Card>
      {matchesDetailsModalOpen && (
        <MatchesDetailsModal
          matchesOn={matchesOn}
          user={user}
          filter={filter}
          open={matchesDetailsModalOpen}
          onClose={() => setMatchesDetailsModalOpen(false)}
        />
      )}
      {openedProject && projectModalOpen && userExperiences?.projects && (
        <ViewProjectModalV2
          selectedProject={openedProject as ExistingProject}
          allProfileSkills={[
            ...skills.requiredSkills,
            ...skills.preferredSkills,
          ].map((s) => skillToExpertise(s))}
          setViewModalOpen={setProjectModalOpen}
          onClose={() => setProjectModalOpen(false)}
          viewModalOpen={projectModalOpen}
          projects={userExperiences?.projects}
          onOpenProject={(projectId) => {
            setOpenedProjectId(projectId);
          }}
        />
      )}
      <Modal
        width={ModalWidth.fixed}
        isOpen={reviewsModalOpen}
        onClose={() => setReviewsModalOpen(false)}
      >
        <Typography variant={'h3'} margin={'xxs'}>
          Select Recommendations ({selectedReviews.length} of {reviews?.length})
        </Typography>
        <Typography variant={'textSmall'}>
          Select {user.fullName}’s recommendations that will be displayed in the
          sample proposal.
        </Typography>
        <div
          style={{
            marginTop: 16,
            marginBottom: 24,
            padding: '8px 12px',
            border: '1px solid #C0C0C0',
          }}
        >
          <Checkbox
            onChange={(e) =>
              e.target.checked
                ? setSelectedReviews((reviews || []).map((r) => r.id))
                : setSelectedReviews([])
            }
            checked={selectedReviews.length === reviews?.length}
            label={'Select all recommendations from past A.Team missions'}
          />
        </div>
        {(reviews || []).map((review) => (
          <div
            key={review.id}
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              padding: 16,
              ...(selectedReviews.includes(review.id) && {
                backgroundColor: '#FBFFF7',
              }),
            }}
          >
            <Checkbox
              style={{ marginTop: 24 }}
              onChange={(e) => {
                e.target.checked
                  ? setSelectedReviews([...selectedReviews, review.id])
                  : setSelectedReviews(
                      selectedReviews.filter((r) => r !== review.id),
                    );
              }}
              checked={selectedReviews.includes(review.id)}
            />
            <Recommendation
              {...review}
              isEditMode={false}
              onVisibilityChange={() => console.info('click')}
            />
          </div>
        ))}
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            onClick={() => {
              setReviewsModalOpen(false);
              onRecommendationsChange &&
                onRecommendationsChange(selectedReviews);
            }}
            width={'auto'}
            size={'small'}
            squared
          >
            Confirm selection
          </Button>
        </div>
      </Modal>
    </li>
  );
};

export default TargeterUserCard;
