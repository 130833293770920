import React, { useMemo } from 'react';
import { FieldErrors, useFormContext } from 'react-hook-form';
import { FieldDatePicker } from '../general/FieldDatePicker';
import { FieldCheckbox } from '../general/FieldCheckbox';
import { FieldDropdown } from '../general/FieldDropdown';
import { FieldTextarea } from '../general/FieldTextarea';

import { createUseStyles } from 'react-jss';
import { useCommonStyles } from '../commonStyles';
import {
  BorderColors,
  BorderRadius,
  Colors,
  SelectOption,
  Spacing,
} from '@ateams/components';

import {
  AccentScoreOptions,
  EnglishLevelOptions,
  ExpertiseScoreOptions,
  InteractionExperienceOptions,
} from '@src/components/ScoreInput/Scores';
import { ScoresError } from '../../VettingFeedbackFormV2';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.backgroundLight,
    borderRadius: BorderRadius.xLarge,
  },
  reasonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
    padding: Spacing.medium,
  },
  dateAndScoresContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
    border: `1px solid ${BorderColors.light}`,
    borderRadius: BorderRadius.xLarge,
    backgroundColor: Colors.backgroundWhite,
    padding: Spacing.medium,
  },
  dateAndFlagsContainer: {
    display: 'grid',
    gridTemplateColumns: '176px 1fr',
    gap: Spacing.medium,
  },
  scoresContainer: {
    display: 'grid',
    gridTemplateColumns: '176px 176px 176px 176px',
    gap: Spacing.medium,
  },
  checkbox: {
    border: `1px solid ${BorderColors.light}`,
    borderRadius: BorderRadius.small,
    paddingTop: Spacing.xsmall,
    paddingBottom: Spacing.xsmall,
    paddingLeft: Spacing.small,
    paddingRight: Spacing.small,
    width: 'fit-content',
  },
});

export interface BuilderScoresProps {
  isViewMode: boolean;
}

export const BuilderScores = ({ isViewMode }: BuilderScoresProps) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const {
    watch,
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  const interviewDate = watch('interviewDate');
  const askedNotToBeRecorded = watch('askedNotToBeRecorded');
  const expertise = watch('scores.expertise');
  const interactionExperience = watch('scores.interactionExperience');
  const englishLevel = watch('scores.englishLevel');
  const accent = watch('scores.accent');
  const expertiseReason = watch('expertiseReason');
  const interactionExperienceReason = watch('interactionExperienceReason');

  const scoresErrors = errors?.scores as FieldErrors<ScoresError> | undefined;

  const expertiseScoreOptions: SelectOption[] =
    ExpertiseScoreOptions as unknown as SelectOption[];
  const interactionExperienceScoreOptions: SelectOption[] =
    InteractionExperienceOptions as unknown as SelectOption[];
  const englishScoreOptions: SelectOption[] =
    EnglishLevelOptions as unknown as SelectOption[];
  const accentScoreOptions: SelectOption[] =
    AccentScoreOptions as unknown as SelectOption[];

  const selectedExpertiseScore = useMemo(
    () => expertiseScoreOptions.find((option) => option.value === expertise),
    [expertiseScoreOptions, expertise],
  );

  const selectedInteractionExperienceScore = useMemo(
    () =>
      interactionExperienceScoreOptions.find(
        (option) => option.value === interactionExperience,
      ),
    [interactionExperienceScoreOptions, interactionExperience],
  );

  const selectedEnglishScore = useMemo(
    () => englishScoreOptions.find((option) => option.value === englishLevel),
    [englishScoreOptions, englishLevel],
  );

  const selectedAccentScore = useMemo(
    () => accentScoreOptions.find((option) => option.value === accent),
    [accentScoreOptions, accent],
  );

  return (
    <div className={commonStyles.section}>
      <div className={commonStyles.sectionTitle}>Interview observations</div>
      <div className={styles.container}>
        <div className={styles.dateAndScoresContainer}>
          <div className={styles.dateAndFlagsContainer}>
            <FieldDatePicker
              label="Date"
              value={interviewDate}
              onChange={(date) => setValue('interviewDate', date)}
              disabled={isViewMode}
            />
            <div className={commonStyles.field}>
              <div className={commonStyles.label}>Flags</div>
              <FieldCheckbox
                text="Asked not to be recorded"
                value={askedNotToBeRecorded}
                onChange={(value) => setValue('askedNotToBeRecorded', value)}
                className={styles.checkbox}
                disabled={isViewMode}
              />
            </div>
          </div>
          <div className={styles.scoresContainer}>
            <FieldDropdown
              label="Expertise level *"
              options={expertiseScoreOptions}
              value={selectedExpertiseScore}
              placeholder="Select"
              onChange={(option) => setValue('scores.expertise', option?.value)}
              error={scoresErrors?.expertise?.message as string}
              name="scores.expertise"
              clearErrors={clearErrors}
              disabled={isViewMode}
            />
            <FieldDropdown
              label="Interpersonal skills *"
              options={interactionExperienceScoreOptions}
              value={selectedInteractionExperienceScore}
              placeholder="Select"
              onChange={(option) =>
                setValue('scores.interactionExperience', option?.value)
              }
              error={scoresErrors?.interactionExperience?.message as string}
              name="scores.interactionExperience"
              clearErrors={clearErrors}
              disabled={isViewMode}
            />
            <FieldDropdown
              label="English proficiency *"
              options={englishScoreOptions}
              value={selectedEnglishScore}
              placeholder="Select"
              onChange={(option) =>
                setValue('scores.englishLevel', option?.value)
              }
              error={scoresErrors?.englishLevel?.message as string}
              name="scores.englishLevel"
              clearErrors={clearErrors}
              disabled={isViewMode}
            />
            <FieldDropdown
              label="English pronunciation *"
              options={accentScoreOptions}
              value={selectedAccentScore}
              placeholder="Select"
              onChange={(option) => setValue('scores.accent', option?.value)}
              error={scoresErrors?.accent?.message as string}
              name="scores.accent"
              clearErrors={clearErrors}
              disabled={isViewMode}
            />
          </div>
        </div>
        <div className={styles.reasonsContainer}>
          <FieldTextarea
            label="Provide reasons for the expertise level score *"
            placeholder="Add your reasons"
            value={expertiseReason ?? ''}
            onChange={(value) => setValue('expertiseReason', value)}
            minLength={40}
            error={errors.expertiseReason?.message as string}
            name="expertiseReason"
            clearErrors={clearErrors}
            disabled={isViewMode}
          />
          <FieldTextarea
            label="Provide reasons for the interpersonal skills score *"
            placeholder="Add your reasons"
            value={interactionExperienceReason ?? ''}
            onChange={(value) => setValue('interactionExperienceReason', value)}
            minLength={40}
            error={errors.interactionExperienceReason?.message as string}
            name="interactionExperienceReason"
            clearErrors={clearErrors}
            disabled={isViewMode}
          />
        </div>
      </div>
    </div>
  );
};
