import {
  CurrentUserMissionApplicationObject,
  CurrentUserMissionApplicationStatus,
  MissionApplicationReviewStatus,
  MissionApplicationReviewStatusNotSelected,
  MissionApplicationReviewStatusOpportunityToUpdate,
  MissionApplicationReviewStatusOther,
  MissionApplicationReviewStatusWaitlisted,
} from '@a_team/models/dist/MissionApplicationObject';
import {
  MissionCardObject,
  MissionStatus,
} from '@a_team/models/dist/MissionObject';
import { MissionRoleStatus } from '@a_team/models/dist/MissionRole';
import { UserBadge } from '@a_team/models/dist/UserObject';

export type Status<
  K extends keyof MissionApplicationReviewStatus = keyof MissionApplicationReviewStatus,
> = {
  name: string;
  description:
    | string
    | ((items: MissionApplicationReviewStatus[K] | undefined) => string);
  color?: string;
};

type Color = {
  color: string;
};

type UserRejectedCategory = Color & {
  multiple: Status<'notSelected'>;
  notSelected: Status;
  skillsMismatch: Status;
  locationMismatch: Status;
  availabilityMismatch: Status;
  experienceMismatch: Status;
  workingHoursMismatch: Status;
  rateMismatch: Status;
  afterProposal: Status;
};

type OpportunityToUpdateCategory = Color & {
  multiple: Status<'opportunityToUpdate'>;
  missingInformation: Status;
  rateNotIdeal: Status;
  workingHoursNotIdeal: Status;
};

type WaitlistedCategory = Color & {
  waitlistedGood: Status;
  waitlistedStrong: Status;
};

type AutomaticPositiveCategory = Color & {
  proposed: Status;
  interviewing: Status;
  selected: Status;
};

type AutomaticNegativeCategory = Color & {
  unavailable: Status;
  notSelected: Status;
  missionConcluded: Status;
  missionCanceled: Status;
  roleCanceled: Status;
};

type AutomaticNeutralCategory = Color & {
  inReview: Status;
  onHold: Status;
  updatedRequestInReview: Status;
  evaluationCallPending: Status;
};

type StatusMapping = {
  UserRejected: UserRejectedCategory;
  OpportunityToUpdate: OpportunityToUpdateCategory;
  Waitlisted: WaitlistedCategory;
  AutomaticPositive: AutomaticPositiveCategory;
  AutomaticNegative: AutomaticNegativeCategory;
  AutomaticNeutral: AutomaticNeutralCategory;
};

export const statusMapping: StatusMapping = {
  UserRejected: {
    color: '#222222',
    multiple: {
      name: 'Not selected',
      description: getMultipleRejectedStatusDescription,
    },
    notSelected: {
      name: 'Not selected',
      description:
        'You meet all requirements for this mission, but other candidates were more competitive or better suited.',
    },
    skillsMismatch: {
      name: 'Skills mismatch',
      description:
        "Your skills don't meet the mission requirements, making you an unsuitable fit.",
    },
    locationMismatch: {
      name: 'Location mismatch',
      description:
        "Your location doesn't match the mission requirements, making you an unsuitable fit.",
    },
    availabilityMismatch: {
      name: 'Availability mismatch',
      description:
        "Your availability doesn't meet the mission requirements, making you an unsuitable fit.",
    },
    experienceMismatch: {
      name: 'Experience mismatch',
      description:
        "Your experience level doesn't meet the mission requirements, making you an unsuitable fit.",
    },
    afterProposal: {
      name: 'Not selected',
      description:
        'The company has decided to move forward with another candidate.',
    },
    workingHoursMismatch: {
      name: 'Working-hours mismatch',
      description:
        "Your working hours don't match the mission requirements, making you an unsuitable fit.",
    },
    rateMismatch: {
      name: 'Rate mismatch',
      description:
        "Your rate exceeds the company's budget, making you an unsuitable fit.",
    },
  },
  OpportunityToUpdate: {
    color: '#AB54FF',
    multiple: {
      name: 'Opportunity to update',
      description: getMultipleOpportunityToUpdateStatusDescription,
    },
    missingInformation: {
      name: 'Missing information',
      description:
        'Provide more relevant experience by updating your project cards, work history, and/or pitch section to be considered.',
    },
    rateNotIdeal: {
      name: 'Rate not ideal',
      description: `Your proposed rate range is outside of the company's budget. To improve your chances of being selected, consider updating your rate range.`,
    },
    workingHoursNotIdeal: {
      name: 'Working hours not ideal',
      description:
        'To be considered, update your working hours request as other candidates have more compatible working hours while meeting mission requirements.',
    },
  },
  Waitlisted: {
    color: '#FFB524',
    waitlistedGood: {
      name: 'Waitlisted',
      description:
        'If the company requests to review more applications, yours may be considered.',
    },
    waitlistedStrong: {
      name: 'Shortlisted',
      description:
        "We’re still reviewing applications, but you're a top contender to be proposed to the company.",
    },
  },
  AutomaticPositive: {
    color: '#79CE00',
    proposed: {
      name: 'Proposed',
      description:
        "We've proposed your request to join the mission to the company. The review process may take a couple of weeks. We'll notify you when there's an update.",
    },
    interviewing: {
      name: 'Interviewing',
      description:
        'The company has requested an interview with you. Check your email for next steps.',
    },
    selected: {
      name: 'Selected',
      description: "You've been selected for this mission.",
    },
  },
  AutomaticNegative: {
    color: '#222222',
    unavailable: {
      name: 'Unavailable',
      description:
        "You're not the right fit for this mission as your availability doesn't meet the requirements.",
    },
    notSelected: {
      name: 'Not selected',
      description: 'The company has decided to proceed with another candidate.',
    },
    missionConcluded: {
      name: 'Mission concluded',
      description: 'This mission has concluded.',
    },
    missionCanceled: {
      name: 'Mission canceled',
      description: 'The company decided not to proceed with this mission.',
    },
    roleCanceled: {
      name: 'Role canceled',
      description:
        'The company changed the scope of this mission, and this role has been removed.',
    },
  },
  AutomaticNeutral: {
    color: '#818388',
    inReview: {
      name: 'In review',
      description:
        "We're reviewing your request to join this mission. We'll notify you when we have an update.",
    },
    updatedRequestInReview: {
      name: 'In review',
      description:
        "We've received your updated request to join this mission. We'll notify you when we have an update.",
    },
    onHold: {
      name: 'On Hold',
      description:
        'We will review your application if the company requests to review more builder profiles.',
      color: '#FFB524',
    },
    evaluationCallPending: {
      name: 'Evaluation call pending',
      description:
        'Complete your evaluation call to be considered. Check your email for details. For questions, contact evaluation@a.team.',
    },
  },
};

type UserWithUsername<T> = T extends { username: string | null }
  ? Pick<T, 'username'>
  : T;

export type MissionStatusFragment = Pick<MissionCardObject, 'status'> & {
  roles: {
    rid: MissionCardObject['roles'][number]['rid'];
    status: MissionCardObject['roles'][number]['status'];
    user: UserWithUsername<MissionCardObject['roles'][number]['user']>;
  }[];
};

export type ApplicationStatusFragment = Pick<
  CurrentUserMissionApplicationObject,
  'rid' | 'reviewStatus' | 'changes' | 'status' | 'updatedAt' | 'lastReviewAt'
> & {
  user: UserWithUsername<CurrentUserMissionApplicationObject['user']>;
};

export function getStatus({
  mission,
  missionApplication,
  userBadges,
  lookingForApplications,
}: {
  mission: MissionStatusFragment;
  missionApplication: ApplicationStatusFragment;
  userBadges?: UserBadge[];
  lookingForApplications?: boolean | null;
}) {
  const { rid, reviewStatus, user } = missionApplication;
  const username = user.username;

  if (
    userBadges?.includes(UserBadge.VettingScheduled) ||
    userBadges?.includes(UserBadge.VettingInterviewDate)
  ) {
    return {
      status: statusMapping.AutomaticNeutral.evaluationCallPending,
      color: statusMapping.AutomaticNeutral.color,
    };
  }

  const statusBasedOnMission = getStatusBasedOnMission(mission, rid, username);

  if (statusBasedOnMission) {
    return statusBasedOnMission;
  }

  if (
    !reviewStatus?.notSelected ||
    !reviewStatus?.opportunityToUpdate ||
    !reviewStatus?.waitlisted ||
    !reviewStatus?.other ||
    (reviewStatus.notSelected.length === 0 &&
      reviewStatus.opportunityToUpdate.length === 0 &&
      reviewStatus.waitlisted.length === 0 &&
      reviewStatus.other.length === 0 &&
      mission.status !== MissionStatus.Ended &&
      missionApplication.status !==
        CurrentUserMissionApplicationStatus.Proposed)
  ) {
    if (lookingForApplications) {
      return {
        status: statusMapping.AutomaticNeutral.inReview,
        color: statusMapping.AutomaticNeutral.color,
      };
    } else {
      return {
        status: statusMapping.AutomaticNeutral.onHold,
        color:
          statusMapping.AutomaticNeutral.onHold?.color ??
          statusMapping.AutomaticNeutral.color,
      };
    }
  }

  if (reviewStatus.notSelected.length > 0) {
    return getUserRejectedStatus(reviewStatus.notSelected);
  }

  if (
    reviewStatus.opportunityToUpdate.length > 0 &&
    missionApplication?.updatedAt &&
    missionApplication?.lastReviewAt &&
    missionApplication?.updatedAt > missionApplication?.lastReviewAt
  ) {
    return {
      status: statusMapping.AutomaticNeutral.updatedRequestInReview,
      color: statusMapping.AutomaticNeutral.color,
    };
  }

  if (reviewStatus.opportunityToUpdate.length > 0) {
    return getOpportunityToUpdateStatus(reviewStatus.opportunityToUpdate);
  }

  const automaticStatus = getAutomaticStatus(
    reviewStatus.other,
    missionApplication,
  );

  if (automaticStatus) {
    return automaticStatus;
  }

  if (reviewStatus.waitlisted.length > 0) {
    return getWaitlistedStatus(reviewStatus.waitlisted[0]);
  }

  return;
}

function getMultipleRejectedStatusDescription(
  notSelected: MissionApplicationReviewStatusNotSelected[] | undefined,
) {
  if (!notSelected) {
    return '';
  }
  const notSelectedList: string[] = [];

  notSelected.forEach((notSelectedReason) => {
    if (
      notSelectedReason.includes(
        MissionApplicationReviewStatusNotSelected.SkillsMismatch,
      )
    ) {
      notSelectedList.push(
        statusMapping.UserRejected.skillsMismatch.name
          .replace('mismatch', '')
          .trim(),
      );
    }
    if (
      notSelectedReason.includes(
        MissionApplicationReviewStatusNotSelected.LocationMismatch,
      )
    ) {
      notSelectedList.push(
        statusMapping.UserRejected.locationMismatch.name
          .replace('mismatch', '')
          .trim(),
      );
    }
    if (
      notSelectedReason.includes(
        MissionApplicationReviewStatusNotSelected.AvailabilityMismatch,
      )
    ) {
      notSelectedList.push(
        statusMapping.UserRejected.availabilityMismatch.name
          .replace('mismatch', '')
          .trim(),
      );
    }
    if (
      notSelectedReason.includes(
        MissionApplicationReviewStatusNotSelected.ExperienceMismatch,
      )
    ) {
      notSelectedList.push(
        statusMapping.UserRejected.experienceMismatch.name
          .replace('mismatch', '')
          .trim(),
      );
    }
    if (
      notSelectedReason.includes(
        MissionApplicationReviewStatusNotSelected.WorkingHoursMismatch,
      )
    ) {
      notSelectedList.push(
        statusMapping.UserRejected.workingHoursMismatch.name
          .replace('mismatch', '')
          .trim(),
      );
    }
    if (
      notSelectedReason.includes(
        MissionApplicationReviewStatusNotSelected.RateMismatch,
      )
    ) {
      notSelectedList.push(
        statusMapping.UserRejected.rateMismatch.name.replace('mismatch', ''),
      );
    }
  });

  const lastReason =
    notSelectedList.length > 1 ? `and ${notSelectedList.pop()}` : '';
  const notSelectedString = notSelectedList.join(', ');

  return `You're not right for this mission because the following requirements are misaligned: ${notSelectedString} ${lastReason}`;
}

function getUserRejectedStatus(
  notSelected: MissionApplicationReviewStatusNotSelected[],
) {
  if (notSelected.length > 1) {
    return {
      status: {
        name: statusMapping.UserRejected.multiple.name,
        description: getMultipleRejectedStatusDescription(notSelected),
      },
      color: statusMapping.UserRejected.color,
    };
  }

  if (
    notSelected.length === 1 &&
    notSelected.includes(
      MissionApplicationReviewStatusNotSelected.AfterInterview,
    )
  ) {
    return {
      status: statusMapping.UserRejected.afterProposal,
      color: statusMapping.UserRejected.color,
    };
  }

  if (
    notSelected.length === 1 &&
    notSelected.includes(
      MissionApplicationReviewStatusNotSelected.SkillsMismatch,
    )
  ) {
    return {
      status: statusMapping.UserRejected.skillsMismatch,
      color: statusMapping.UserRejected.color,
    };
  }

  if (
    notSelected.length === 1 &&
    notSelected.includes(
      MissionApplicationReviewStatusNotSelected.AfterProposal,
    )
  ) {
    return {
      status: statusMapping.UserRejected.afterProposal,
      color: statusMapping.UserRejected.color,
    };
  }

  if (
    notSelected.length === 1 &&
    notSelected.includes(
      MissionApplicationReviewStatusNotSelected.OverallWeakCandidate,
    )
  ) {
    return {
      status: statusMapping.UserRejected.notSelected,
      color: statusMapping.UserRejected.color,
    };
  }

  if (
    notSelected.length === 1 &&
    notSelected.includes(
      MissionApplicationReviewStatusNotSelected.LocationMismatch,
    )
  ) {
    return {
      status: statusMapping.UserRejected.locationMismatch,
      color: statusMapping.UserRejected.color,
    };
  }

  if (
    notSelected.length === 1 &&
    notSelected.includes(
      MissionApplicationReviewStatusNotSelected.AvailabilityMismatch,
    )
  ) {
    return {
      status: statusMapping.UserRejected.availabilityMismatch,
      color: statusMapping.UserRejected.color,
    };
  }

  if (
    notSelected.length === 1 &&
    notSelected.includes(
      MissionApplicationReviewStatusNotSelected.ExperienceMismatch,
    )
  ) {
    return {
      status: statusMapping.UserRejected.experienceMismatch,
      color: statusMapping.UserRejected.color,
    };
  }

  if (
    notSelected.length === 1 &&
    notSelected.includes(
      MissionApplicationReviewStatusNotSelected.WorkingHoursMismatch,
    )
  ) {
    return {
      status: statusMapping.UserRejected.workingHoursMismatch,
      color: statusMapping.UserRejected.color,
    };
  }

  if (
    notSelected.length === 1 &&
    notSelected.includes(MissionApplicationReviewStatusNotSelected.RateMismatch)
  ) {
    return {
      status: statusMapping.UserRejected.rateMismatch,
      color: statusMapping.UserRejected.color,
    };
  }

  return;
}

function getMultipleOpportunityToUpdateStatusDescription(
  opportunityToUpdate:
    | MissionApplicationReviewStatusOpportunityToUpdate[]
    | undefined,
) {
  if (!opportunityToUpdate) {
    return '';
  }

  const Status = MissionApplicationReviewStatusOpportunityToUpdate;

  const hasWorkingHoursNotIdeal = opportunityToUpdate.includes(
    Status.WorkingHoursNotIdeal,
  );
  const hasMissingInformation = opportunityToUpdate.includes(
    Status.MissingInformation,
  );
  const hasRateNotIdeal = opportunityToUpdate.includes(Status.RateNotIdeal);

  if (hasWorkingHoursNotIdeal && hasMissingInformation && hasRateNotIdeal) {
    return `Provide more relevant experience by updating your project cards, work history, and/or pitch section. Consider adjusting your rate and working hours to remain competitive with other builders.`;
  }

  if (hasRateNotIdeal && hasWorkingHoursNotIdeal) {
    return `You meet the requirements for this mission, however, other candidates have more competitive rate and working hours. To still be considered, update your request.`;
  }

  if (hasRateNotIdeal && hasMissingInformation) {
    return `Provide more relevant experience by updating your project cards, work history, and/or pitch section. Consider adjusting your rate to remain competitive with other builders.`;
  }

  if (hasWorkingHoursNotIdeal && hasMissingInformation) {
    return `Provide more relevant experience by updating your project cards, work history, and/or pitch section. Consider adjusting your working hours to remain competitive with other builders.`;
  }

  return '';
}

function getOpportunityToUpdateStatus(
  opportunityToUpdate: MissionApplicationReviewStatusOpportunityToUpdate[],
) {
  if (opportunityToUpdate.length > 1) {
    return {
      status: {
        name: statusMapping.OpportunityToUpdate.multiple.name,
        description:
          getMultipleOpportunityToUpdateStatusDescription(opportunityToUpdate),
      },
      color: statusMapping.OpportunityToUpdate.color,
    };
  }

  if (
    opportunityToUpdate.includes(
      MissionApplicationReviewStatusOpportunityToUpdate.MissingInformation,
    )
  ) {
    return {
      status: statusMapping.OpportunityToUpdate.missingInformation,
      color: statusMapping.OpportunityToUpdate.color,
    };
  }

  if (
    opportunityToUpdate.length === 1 &&
    opportunityToUpdate.includes(
      MissionApplicationReviewStatusOpportunityToUpdate.RateNotIdeal,
    )
  ) {
    return {
      status: statusMapping.OpportunityToUpdate.rateNotIdeal,
      color: statusMapping.OpportunityToUpdate.color,
    };
  }

  if (
    opportunityToUpdate.length === 1 &&
    opportunityToUpdate.includes(
      MissionApplicationReviewStatusOpportunityToUpdate.WorkingHoursNotIdeal,
    )
  ) {
    return {
      status: statusMapping.OpportunityToUpdate.workingHoursNotIdeal,
      color: statusMapping.OpportunityToUpdate.color,
    };
  }

  return;
}

function getWaitlistedStatus(
  waitlisted: MissionApplicationReviewStatusWaitlisted,
) {
  if (waitlisted === MissionApplicationReviewStatusWaitlisted.WaitlistedGood) {
    return {
      status: statusMapping.Waitlisted.waitlistedGood,
      color: statusMapping.Waitlisted.color,
    };
  }

  if (
    waitlisted === MissionApplicationReviewStatusWaitlisted.WaitlistedStrong
  ) {
    return {
      status: statusMapping.Waitlisted.waitlistedStrong,
      color: statusMapping.Waitlisted.color,
    };
  }

  return;
}

function getStatusBasedOnMission(
  mission: MissionStatusFragment,
  roleId: string,
  username: string,
) {
  const userRole = mission.roles.find((role) => role.rid === roleId);

  if (
    (mission.status === MissionStatus.Ended ||
      mission.status === MissionStatus.ScheduledToEnd) &&
    userRole &&
    userRole.user &&
    userRole.user.username === username
  ) {
    return {
      status: statusMapping.AutomaticNegative.missionConcluded,
      color: statusMapping.AutomaticNegative.color,
    };
  }

  if (!userRole || userRole?.status === MissionRoleStatus.Canceled) {
    return {
      status: statusMapping.AutomaticNegative.roleCanceled,
      color: statusMapping.AutomaticNegative.color,
    };
  }

  if (userRole && userRole.user && userRole.user.username === username) {
    return {
      status: statusMapping.AutomaticPositive.selected,
      color: statusMapping.AutomaticPositive.color,
    };
  }

  if (mission.status === MissionStatus.Archived) {
    return {
      status: statusMapping.AutomaticNegative.missionCanceled,
      color: statusMapping.AutomaticNegative.color,
    };
  }

  if (
    userRole &&
    userRole.user &&
    userRole.user.username !== username &&
    [
      MissionRoleStatus.Open,
      MissionRoleStatus.Active,
      MissionRoleStatus.ScheduledToEnd,
      MissionStatus.Running,
      MissionStatus.Ended,
    ].includes(userRole?.status)
  ) {
    return {
      status: statusMapping.AutomaticNegative.notSelected,
      color: statusMapping.AutomaticNegative.color,
    };
  }

  return;
}

function getAutomaticStatus(
  reviewStatusOther: MissionApplicationReviewStatusOther[],
  missionApplication: ApplicationStatusFragment,
) {
  if (
    reviewStatusOther.includes(MissionApplicationReviewStatusOther.Unavailable)
  ) {
    return {
      status: statusMapping.AutomaticNegative.unavailable,
      color: statusMapping.AutomaticNegative.color,
    };
  }

  const rateUpdate = missionApplication.changes?.rateUpdatedAt;
  const lastReviewedAt = missionApplication.changes?.lastReviewAt;
  const workingHoursUpdate = missionApplication.changes?.workingHoursUpdatedAt;
  const hasRateUpdate =
    lastReviewedAt && rateUpdate && rateUpdate > lastReviewedAt;
  const hasWorkingHoursUpdate =
    lastReviewedAt && workingHoursUpdate && workingHoursUpdate > lastReviewedAt;
  const hasChangesToUpdate = hasRateUpdate || hasWorkingHoursUpdate;

  if (missionApplication.changes && hasChangesToUpdate) {
    return {
      status: statusMapping.AutomaticNeutral.inReview,
      color: statusMapping.AutomaticNeutral.color,
    };
  }

  if (
    missionApplication.status === CurrentUserMissionApplicationStatus.Proposed
  ) {
    return {
      status: statusMapping.AutomaticPositive.proposed,
      color: statusMapping.AutomaticPositive.color,
    };
  }

  if (
    missionApplication.status ===
      CurrentUserMissionApplicationStatus.Interviewing ||
    reviewStatusOther.includes(
      MissionApplicationReviewStatusOther.InterviewingFromFormation,
    )
  ) {
    return {
      status: statusMapping.AutomaticPositive.interviewing,
      color: statusMapping.AutomaticPositive.color,
    };
  }

  return;
}
