import { DateISOString } from './misc';
import { BasicUserObject, SenderUserObject, UserUsername } from './UserObject';
import MissionRole, {
  BasicMissionRole,
  MissionAdminRole,
  RecommendedRole,
} from './MissionRole';
import { PaymentCycles, PaymentCyclesAdmin } from './MissionPaymentCycleObject';
import { TalentIndustrySummary } from './TalentIndustry';
import BillingAccountObject, { BillingPaymentTerms } from './BillingAccount';
import { AccountId } from './Account';

export type MissionId = string;

export enum MissionStatus {
  Created = 'Created',
  Published = 'Published',
  Pending = 'Pending',
  Running = 'Running',
  ScheduledToEnd = 'ScheduledToEnd',
  Ended = 'Ended',
  Archived = 'Archived',
}

export enum MissionApplyStatus {
  Collect = 'Collect',
  Review = 'Review',
  Finalize = 'Finalize',
  Deployed = 'Deployed',
}

export const MissionApplyStatusLabels: Record<MissionApplyStatus, string> = {
  [MissionApplyStatus.Collect]: 'Drafting Team',
  [MissionApplyStatus.Review]: 'Reviewing Teams',
  [MissionApplyStatus.Finalize]: 'Finalizing Team',
  [MissionApplyStatus.Deployed]: 'Team Deployed',
};

export enum MissionBillingPeriod {
  Daily = 'Daily (Test)',
  Weekly = 'Weekly',
  BiWeekly = 'BiWeekly',
  Monthly = 'Monthly',
}

export enum MissionInvoicingPeriod {
  BiWeekly = 'BiWeekly',
  Monthly = 'Monthly',
}

export enum MissionThumbnailColor {
  RadicalRed = '#FC2A50',
  TreePoppy = '#FE8923',
  SchoolBusYellow = '#FFDF00',
  ChateauGreen = '#34B956',
  Chartreuse = '#8CDF04',
  DodgerBlue = '#3CB9FF',
  Heliotrope = '#BF5EFF',
  Porsche = '#ECA86C',
  VividTangerine = '#FF877B',
  WinterHazel = '#D7CD9F',
}

export enum MissionCardColor {
  Heliotrope = '#AB54FF',
  DodgerBlue = '#08A5FE',
  Sun = '#FFAF14',
}

export interface MissionLink {
  title: string;
  URL: string;
}

export interface MissionSearchResult extends Omit<MissionLink, 'URL'> {
  status: MissionStatus;
  mid: MissionId;
}

export interface MissionCompanyRequest {
  companyName: string;
  websiteURL: string;
  fullName: string;
  role: string;
  email: string;
  phoneNumber: string;
  description: string;
  notes?: string;
}

export interface MinimalMissionObject {
  mid: MissionId;
  title: string;
}

export interface BasicMissionObject extends MinimalMissionObject {
  mid: MissionId;
  title: string;
  description: string;
  status: MissionStatus;
  applyStatus: MissionApplyStatus;
  logoURL?: string;
  thumbnailColor?: MissionThumbnailColor;
  cardColor?: MissionCardColor;
  missionURL?: string;
  missionSpecId?: string;
  website?: string;
  selectedStatus?: MissionStatus;
  internalMission?: boolean;
  accountId?: AccountId;
}

export interface BasicRoleMissionObject extends BasicMissionObject {
  role: BasicMissionRole;
}

export type MissionConnections = {
  connectionsApplied: BasicUserObject[];
  connectionsAssigned: BasicUserObject[];
};

export interface MissionCardObject extends BasicMissionObject {
  shortCompanyDescription?: string;
  companyStory?: string;
  companyName?: string;
  expectedDurationMonths?: number;
  attachedLinks?: MissionLink[] | null;
  videoURL?: string;
  roles: BasicMissionRole[];
  hidden: boolean;
  promotedTags: string[];
  creator: BasicUserObject | null;
  publisher?: BasicUserObject;
  publishedAt?: DateISOString;
  startedAt?: DateISOString;
  updatedAt?: DateISOString;
  endedAt?: DateISOString;
  industries: TalentIndustrySummary[];
  owner?: BasicUserObject;
  bdOwners?: SenderUserObject[];
  missionConnections?: MissionConnections;
}

interface MissionPartialObject extends MissionCardObject {
  createdAt: DateISOString;
  roles: MissionRole[];
  managers?: MissionManager[];
  internalDescription?: string;
}

export default interface MissionObject extends MissionPartialObject {
  isMember?: boolean;
  paymentCycles?: PaymentCycles;
  owner?: BasicUserObject & { email: string };
  // Adding the field `billingPeriod` to this interface facilitates the
  // front-end validation that needs to happen for when the role is a monthly
  // retainer and there is a minimum amount of days that need to be filled
  billingPeriod: MissionBillingPeriod;
}

export enum MissionManagerAccessMode {
  RoleView = 'RoleView',
}

export interface MissionManager {
  accessMode: MissionManagerAccessMode;
  excludeFromInvoiceEmails?: boolean;
  excludeFromTeamPulseEmails?: boolean;
  excludeFromBuilderFeedbackEmails?: boolean;
  excludeFromMissionUpdatesEmails?: boolean;
  username: UserUsername;
  user: BasicUserObject;
}

export interface MissionAdminObject extends MissionPartialObject {
  companyRequest?: MissionCompanyRequest;
  roles: MissionAdminRole[];
  managers: MissionManager[];
  billingPeriod: MissionBillingPeriod;
  automaticInvoicingPeriod?: MissionInvoicingPeriod;
  clientMargin: number;
  rolesMargin: number;
  skipContracts?: boolean;
  accountId?: AccountId;
  billingAccount?: BillingAccountObject;
  paymentCycles?: PaymentCyclesAdmin;
  invoiceEmailGreeting?: string;
  invoicing?: MissionInvoicing;
  mainManagerUsername?: string;
  owner?: BasicUserObject & { email: string };
  paymentTerms?: BillingPaymentTerms;
  hubspotDealId?: string;
  automatedStatusesDisabled?: boolean;
}

export interface MissionInvoicing {
  purchaseOrderNumber?: string;
}

export interface RecommendedMission extends MissionCardObject {
  roles: RecommendedRole[];
}

export interface TimesheetMigrationDataRequest {
  timesheetId: string;
  newMissionId: string;
}
